import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import classnames from "classnames";
import { Typography } from "chat/imports/components";
import { MessageMenuOptionType } from "chat/types";
import { TYPOGRAPHY_TYPE } from "src/features/broadcastExternal/imports/components";
import { VoidCallback } from "src/types/common";
import styles from "./MessageMenuOptionBase.scss";

interface MessageMenuOptionBaseProps {
  onClick: VoidCallback;
  option: MessageMenuOptionType;
}

const MessageMenuOptionBase: FC<MessageMenuOptionBaseProps> = ({
  option,
  onClick,
}) => {
  const { message, icon: Icon, id, visibilityFn } = option;
  const visible = useSelector(visibilityFn);

  if (!visible) {
    return null;
  }

  return (
    <div
      className={classnames(styles.wrap, {
        [styles.highlight]: option.id === "delete",
      })}
      data-testId={`chat-message-menu-option-${id}`}
      onClick={onClick}
    >
      <Icon />
      <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH3}>
        <FormattedMessage {...message} />
      </Typography>
    </div>
  );
};

export default MessageMenuOptionBase;
