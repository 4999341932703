import React, {
  FC,
  MutableRefObject,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import Hls from "hls.js";
import StreamAnalyticsContext from "@analytics/livePlay/StreamAnalyticsContext";
import { getHdVideoEnabled, getShouldLogHls } from "environment";
import { DeviceType } from "src/enums";
import { Nullable, VoidCallback } from "src/types/common";
import { deviceInfoSelectors } from "state/selectors";
import { getPlaylistUrl, printLevels } from "ui/player/Player";
import PlayerEventContext from "ui/scenes/stream/PlayerEventContext";
import { useMount, useUnmount } from "utils/miniReactUse";
import styles from "./HLSPlayer.scss";

// we are printing some useful info here
/* eslint-disable no-console */

const hlsConfig = {
  startFragPrefetch: true,
  liveSyncDurationCount: 3,
  forceKeyFrameOnDiscontinuity: false,
  liveDurationInfinity: true,
  liveBackBufferLength: 2,
};

interface HLSPlayerProps {
  forceDisableHd: boolean;
  forwardedRef: MutableRefObject<HTMLVideoElement>;
  muted: boolean;
  paused: boolean;
  poster: string;
  src: string;
  videoEventListeners: Record<string, VoidCallback>;
}

const HLSPlayer: FC<HLSPlayerProps> = ({
  forwardedRef,
  src,
  poster,
  muted,
  videoEventListeners,
  forceDisableHd,
  paused,
  ...rest
}) => {
  const videoRef = useRef<Nullable<HTMLVideoElement>>(null);
  const hlsRef = useRef<Nullable<Hls>>(null);

  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);

  const [isVideoHidden, setIsVideoHidden] = useState(true);
  const [isPlayerInitialized, setIsPlayerInitialized] = useState(false);
  const { logStreamError } = useContext(StreamAnalyticsContext);
  const shouldLogHls = getShouldLogHls();
  const {
    setStreamStartTimestamp,
    setPlayerDelay,
    setManifestLoadingStartTime,
    setFirstFrameTime,
    setLevelSwitchingInfo,
  } = useContext(PlayerEventContext);

  const playWithNativePlayer =
    !Hls.isSupported() ||
    (videoRef.current?.canPlayType("application/vnd.apple.mpegurl") &&
      deviceType !== DeviceType.ANDROID);
  const playlistUrl = useMemo(() => getPlaylistUrl(src), [src]);

  const hlsListeners = useMemo(() => {
    const video = videoRef.current;
    const hls = hlsRef.current;

    if (!video || !hls) {
      return {};
    }

    return {
      [Hls.Events.LEVEL_SWITCHED]: () => {
        setLevelSwitchingInfo?.({
          ...hls.levels[hls.currentLevel],
          switchingTime: Date.now(),
        });

        if (shouldLogHls) {
          console.group("HLS LEVEL_SWITCHED");
          printLevels(hls);
          console.groupEnd();
        }
      },
      [Hls.Events.MANIFEST_LOADING]: () => {
        setManifestLoadingStartTime?.(Date.now());
      },
      // @ts-ignore ToDo: move StreamAnalyticsContext to TS https://tango-me.atlassian.net/browse/WEB-6918
      [Hls.Events.MANIFEST_PARSED]: (_, { levels }) => {
        setStreamStartTimestamp?.(Date.now());
        if (
          (!getHdVideoEnabled() || forceDisableHd) &&
          levels &&
          levels.length
        ) {
          // this will force player to play minimum available level
          const minLevel = levels.reduce(
            // @ts-ignore ToDo: move StreamAnalyticsContext to TS https://tango-me.atlassian.net/browse/WEB-6918
            (a, x, i) => (levels[a].width < x.width ? a : i),
            0
          );
          hls.currentLevel = minLevel;

          if (shouldLogHls) {
            console.group("HLS MANIFEST_PARSED");
            console.info(
              "hls HD is disabled, selected level details:",
              hls.levels[minLevel]
            );
            printLevels(hls);
            console.groupEnd();
          }
        }
      },
      // @ts-ignore ToDo: move StreamAnalyticsContext to TS https://tango-me.atlassian.net/browse/WEB-6918
      [Hls.Events.ERROR]: (_, data) => {
        const { fatal, type, details } = data;
        // @ts-ignore ToDo: move StreamAnalyticsContext to TS https://tango-me.atlassian.net/browse/WEB-6918
        logStreamError?.(`HLS error: ${type}, ${details}`);

        if (!fatal) {
          return;
        }

        switch (type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            hls.recoverMediaError();
            break;
        }
      },
      [Hls.Events.BUFFER_APPENDING]: () => {
        setPlayerDelay?.({
          buffered: video.buffered,
          currentTime: video.currentTime,
        });
      },
    };
  }, [
    forceDisableHd,
    logStreamError,
    setLevelSwitchingInfo,
    setManifestLoadingStartTime,
    setPlayerDelay,
    setStreamStartTimestamp,
    shouldLogHls,
    hlsRef.current,
  ]);

  useEffect(() => {
    const hls = hlsRef.current;

    if (!hls) {
      return;
    }

    // @ts-ignore ToDo: move StreamAnalyticsContext to TS https://tango-me.atlassian.net/browse/WEB-6918
    Object.entries(hlsListeners).forEach(([e, h]) => hls.on(e, h));

    return () => {
      // @ts-ignore ToDo: move StreamAnalyticsContext to TS https://tango-me.atlassian.net/browse/WEB-6918
      Object.entries(hlsListeners).forEach(([e, h]) => hls.off(e, h));
    };
  }, [hlsListeners, hlsRef.current]);

  useMount(() => {
    const video = videoRef.current;

    if (!video) {
      return;
    }

    hlsRef.current = new Hls({
      ...hlsConfig,
      xhrSetup: (xhr, uri) => {
        // do send cookies for playlist - necessary for private vids to work
        // don't send cookies for fragments - content-server doesn't set correct headers
        xhr.withCredentials =
          !(uri.indexOf(".mp4") > -1) && !(uri.indexOf(".ts") > -1);
      },
    });
  });

  useUnmount(() => {
    const hls = hlsRef.current;

    if (hls) {
      hlsRef.current?.destroy();
    }

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = "";
      videoRef.current.load();
    }
  });

  useEffect(() => {
    if (!isPlayerInitialized) {
      return;
    }

    // To be in synergy with HLC we need to call pause and play only when video node in correct statuses
    const video = videoRef.current;
    // we need to check readyState to prevent this error https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    if (video && video.readyState >= video.HAVE_CURRENT_DATA) {
      const videoPaused = video.paused;

      if (paused && !videoPaused) {
        video.pause();
      } else if (!paused && videoPaused) {
        video.play().catch(emptyFunction);
      }
    }
  }, [paused, isPlayerInitialized]);

  useLayoutEffect(() => {
    const video = videoRef.current;

    if (!videoEventListeners) {
      return;
    }

    Object.entries(videoEventListeners).forEach(([e, h]) =>
      video?.addEventListener(e, h)
    );

    return () => {
      Object.entries(videoEventListeners).forEach(([e, h]) =>
        video?.removeEventListener(e, h)
      );
    };
  }, [videoEventListeners]);

  useEffect(() => {
    const video = videoRef.current;
    const hls = hlsRef.current;

    if (!video || !hls) {
      return;
    }

    if (playWithNativePlayer) {
      if (video?.src !== playlistUrl) {
        video.src = playlistUrl;
      }

      video.play().catch(async (...args) => {
        console.log("NATIVE_ERROR_PLAY", ...args);
      });
    } else {
      hls.loadSource(getPlaylistUrl(src));
      hls.attachMedia(video);

      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        video.pause();
        video.play().catch(emptyFunction);
      });
    }

    setIsPlayerInitialized(true);
    setIsVideoHidden(false);

    return () => {
      setIsPlayerInitialized(false);
      hls.detachMedia();
    };
  }, [logStreamError, src, hlsRef.current]);

  useLayoutEffect(() => {
    const video = videoRef.current;

    if (!video) {
      return;
    }

    setIsVideoHidden(true);
  }, [src]);

  const onLoadedData = () => setFirstFrameTime?.(Date.now());

  const onMount = (ref: HTMLVideoElement) => {
    videoRef.current = ref;
    forwardedRef.current = ref;
  };

  return (
    <video
      onLoadedData={onLoadedData}
      className={classnames(styles.root, {
        [styles.hidden]: isVideoHidden,
      })}
      ref={onMount}
      muted={muted}
      poster={poster}
      disablePictureInPicture
      playsInline
      {...rest}
      crossOrigin="use-credentials"
    />
  );
};

export default HLSPlayer;
