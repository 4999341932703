import { ACCOUNT_TAGS_UPDATE } from "src/features/signin/imports/actionTypes";
import {
  EventFields,
  EventNames,
  loginProviderToRegType,
} from "src/features/signin/imports/analytics";
import {
  ADD_LOGIN_PROVIDER,
  AUTHORIZATION_END,
  AUTHORIZATION_START,
  LOGIN_CANCEL,
  LOGIN_CONTINUE_WITH_PHONE,
  LOGIN_END,
  LOGIN_ENTRY_POINT,
  LOGIN_HIDE_IN_PROGRESS_STATE,
  LOGIN_PROVIDERS_PREPARATION_ENDED,
  LOGIN_PROVIDERS_PREPARATION_STARTED,
  LOGIN_SELECT_COUNTRY,
  LOGIN_START,
  LOGIN_WAITING_FOR_CODE,
  LOGOUT_3RD_PARTY_END,
  LOGOUT_END,
  LOGOUT_START,
  MARK_SESSION_LIMITED,
  PHONE_LOGIN_ATTEMPT_LIMIT_REACHED,
  PHONE_LOGIN_METHOD_UNAVAILABLE_FOR_REGISTERED_USER,
  PHONE_LOGIN_PHONE_NUMBER_SPECIFIED,
  PHONE_LOGIN_PHONE_VERIFICATION_UNAVAILABLE,
  PHONE_LOGIN_RESEND_CODE,
  PHONE_LOGIN_RESET,
  PHONE_LOGIN_SET_COUNTRY_INFO,
  PHONE_LOGIN_SET_PREFERS_SMS,
  PHONE_LOGIN_SET_SMS_RESEND_ALLOWED_TIMESTAMP,
  PHONE_LOGIN_VERIFICATION_CODE_SPECIFIED,
  PHONE_LOGIN_VERIFICATION_FAILED,
  PHONE_LOGIN_VERIFICATION_REQUIRED,
  PHONE_LOGIN_VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE,
  SET_REFERRER_ACCOUNT_ID,
} from "src/features/signin/state/login/actionTypes";

export const setReferrerAccountId = (accountId) => ({
  type: SET_REFERRER_ACCOUNT_ID,
  payload: accountId,
});

export const specifyPhoneNumberForPhoneLogin = (phoneNumber) => ({
  type: PHONE_LOGIN_PHONE_NUMBER_SPECIFIED,
  phoneNumber,
});

export const askForSms = () => ({
  type: PHONE_LOGIN_SET_PREFERS_SMS,
});

export const specifyVerificationCodeForPhoneLogin = (verificationCode) => ({
  type: PHONE_LOGIN_VERIFICATION_CODE_SPECIFIED,
  verificationCode,
});

export const resetPhoneLogin = () => ({
  type: PHONE_LOGIN_RESET,
});

export const phoneLoginVerificationRequired = ({
  codeProvider,
  deliveryMethod,
}) => ({
  type: PHONE_LOGIN_VERIFICATION_REQUIRED,
  payload: {
    codeProvider,
    deliveryMethod,
  },
});

export const phoneLoginResendCode = () => ({
  type: PHONE_LOGIN_RESEND_CODE,
});

export const phoneLoginMethodUnavailableForRegisteredUser = () => ({
  type: PHONE_LOGIN_METHOD_UNAVAILABLE_FOR_REGISTERED_USER,
});

export const phoneLoginVerificationRequiredSMSNotPossible = () => ({
  type: PHONE_LOGIN_VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE,
});

export const phoneLoginSetSmsResendDelay = (resendDelayInSeconds = 0) => ({
  type: PHONE_LOGIN_SET_SMS_RESEND_ALLOWED_TIMESTAMP,
  resendAllowedTimestamp: new Date().getTime() + resendDelayInSeconds * 1000,
});

export const phoneLoginAttemptLimitReached = () => ({
  type: PHONE_LOGIN_ATTEMPT_LIMIT_REACHED,
});

export const phoneLoginPhoneVerificationUnavailable = () => ({
  type: PHONE_LOGIN_PHONE_VERIFICATION_UNAVAILABLE,
});

export const phoneLoginVerificationFailed = (errorMessage) => ({
  type: PHONE_LOGIN_VERIFICATION_FAILED,
  errorMessage,
});

export const loginSetPhoneCountryInfo = ({ countryCode, countryIcon }) => ({
  type: PHONE_LOGIN_SET_COUNTRY_INFO,
  countryCode,
  countryIcon,
});

export const loginContinueWithPhone = () => ({
  type: LOGIN_CONTINUE_WITH_PHONE,
});

export const loginSetSelectCountryStep = (payload) => ({
  type: LOGIN_SELECT_COUNTRY,
  payload,
});

export const loginWaitingForCode = (payload) => ({
  type: LOGIN_WAITING_FOR_CODE,
  payload,
});

export const loginSetEntryPoint = (payload) => ({
  type: LOGIN_ENTRY_POINT,
  payload,
});

export const loginStart = () => ({
  type: LOGIN_START,
});

export const loginCancelled = () => ({
  type: LOGIN_CANCEL,
});

export const loginHideInProgressFlag = () => ({
  type: LOGIN_HIDE_IN_PROGRESS_STATE,
});

export const loginSuccess = ({
  accountId,
  anchorType = null,
  newRegistration,
  connectionManagerData,
  provider,
  clientCapabilities,
  isLimitedSession = false,
  entryPoint,
}) => ({
  type: LOGIN_END,
  payload: {
    accountId,
    connectionManagerData,
    provider,
    clientCapabilities,
    isLimitedSession,
  },
  meta: {
    analytics: {
      actionName: newRegistration
        ? EventNames.REGISTRATION_COMPLETED
        : EventNames.LOGIN_COMPLETED,
      params: {
        [EventFields.LOGIN_PROVIDER]: loginProviderToRegType(provider),
        [EventFields.COMMENT]: entryPoint,
        [EventFields.USER_ID]: accountId,
        ...(anchorType && { [EventFields.ANCHOR_TYPE]: anchorType }),
      },
    },
  },
});

export const authorizationStart = () => ({ type: AUTHORIZATION_START });

export const setSessionLimited = ({ isSessionLimited }) => ({
  type: MARK_SESSION_LIMITED,
  payload: { isSessionLimited },
});

export const authorizationSuccess = ({ accountId, connectionManagerData }) => ({
  type: AUTHORIZATION_END,
  payload: { accountId, connectionManagerData },
});

export const authorizationFailed = ({ error }) => ({
  type: AUTHORIZATION_END,
  error: true,
  payload: error instanceof Error ? error.toString() : error,
});

export const loginFailed = (reason) => ({
  type: LOGIN_END,
  payload: reason,
  error: true,
});

export const logoutStart = () => ({ type: LOGOUT_START });

export const logoutSuccess = () => ({
  type: LOGOUT_END,
});

export const logoutFailed = (error) => ({
  type: LOGOUT_END,
  error: true,
  payload: error,
});

export const loadedLoginProvider = (provider) => ({
  type: ADD_LOGIN_PROVIDER,
  payload: provider,
});

export const failedToLoadLoginProvider = ({ provider, error }) => ({
  type: ADD_LOGIN_PROVIDER,
  error: true,
  payload: error instanceof Error ? error.toString() : error,
  meta: { provider },
});

export const failedToLogoutFromProvider = ({ error, provider }) => ({
  type: LOGOUT_3RD_PARTY_END,
  error: true,
  payload: error,
  meta: { provider },
});

export const beganPreparingProviders = () => ({
  type: LOGIN_PROVIDERS_PREPARATION_STARTED,
});

export const endedPreparingProviders = () => ({
  type: LOGIN_PROVIDERS_PREPARATION_ENDED,
});

export const setAccountTags = ({ tags, updateTime }) => ({
  type: ACCOUNT_TAGS_UPDATE,
  payload: tags,
  meta: { updateTime },
});
