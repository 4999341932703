import { createSlice } from "@reduxjs/toolkit";
import { addUserSessionScopeReducer } from "src/features/visitorCashier/imports/state";
import {
  Nullable,
  PersonalOffersConfig,
} from "src/features/visitorCashier/imports/types";
import {
  loadMarketingPersonalOffers,
  loadVisitorMarketingPersonalOffers,
} from "src/features/visitorCashier/state/asyncAction";

export interface VisitorCashierState {
  offers: Nullable<PersonalOffersConfig>;
  visitorOffers: Nullable<PersonalOffersConfig>;
}

const initialState: VisitorCashierState = {
  visitorOffers: null,
  offers: null,
};

const slice = createSlice({
  name: "visitorCashier",
  initialState,
  reducers: {},
  extraReducers(builder) {
    addUserSessionScopeReducer(
      builder
        .addCase(
          loadVisitorMarketingPersonalOffers.fulfilled,
          (state, action) => {
            state.visitorOffers = action.payload;
          }
        )
        .addCase(loadMarketingPersonalOffers.fulfilled, (state, action) => {
          state.offers = action.payload;
        }),
      () => initialState
    );
  },
});

export const visitorCashierReducer = slice.reducer;
