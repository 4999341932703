import { createReducer } from "@reduxjs/toolkit";
import { BROADCAST_TITLE_MAX_LENGTH } from "src/constants";
import { BroadcastPictureUploadStatus, BroadcastStatus } from "src/enums";
import { MEDIA_DEVICES_PERMISSION_SHOWN_KEY } from "src/features/broadcastLobby/exports/constants";
import { LOGOUT_END } from "src/features/signin/exports/state/actionTypes";
import { Nullable } from "src/types/common";
import { StreamKind } from "src/types/richFragment/Stream";
import { isEmptyOrWhitespace } from "src/utils/miniLodash";
import * as broadcastActionCreators from "state/actionCreators/broadcast";

export interface MultiBroadcastInvite {
  action: string;
  badgeCount: number;
  category: string;
  countryIconUrl?: string;
  countryIsoCode?: string;
  hostAccountId: string;
  hostCoverUrl: string;
  hostFirstName: string;
  hostFullName: string;
  hostLPBonus: number;
  hostLPBonusV2: number;
  hostLastName: string;
  hostPreviewUrl: string;
  hostStreamId: string;
  hostThumbnailUrl: string;
  hostTotalPoint: number;
  hostViewerCount: number;
  id: string;
  invitationType?: string;
  isBattleRequest?: boolean;
  isPremium: boolean;
  message: string;
  requestId: string;
  serverOnly: string;
  soundFile: string;
  sourceUuid: Nullable<string>;
  title: string;
  type: string;
}

export interface BroadcastState {
  broadcastBanDuration?: number;
  broadcastId?: string;
  broadcastKey?: string;
  broadcastKind?: StreamKind;
  broadcastPictureStatus: BroadcastPictureUploadStatus;
  broadcastPictureThumbnailUrl?: string;
  broadcastPictureUrl?: string;
  broadcastScreenShareEnabled: boolean;
  broadcastSource?: string;
  broadcastStatus?: string;
  broadcastTitle: string;
  broadcastVideoEnabled: boolean;
  mobileMediaPermissionsGranted: boolean;
  multiBroadcastInvite?: MultiBroadcastInvite;
  startedPlayingPipsIds: string[];
}

export const broadcastPersistConfig = {
  whitelist: [
    "broadcastPictureStatus",
    "broadcastPictureUrl",
    "broadcastPictureThumbnailUrl",
    "broadcastTitle",
  ],
};

export const getInitialMobileMediaPermissionsValue = () => {
  const storedValue = localStorage.getItem(MEDIA_DEVICES_PERMISSION_SHOWN_KEY);

  if (storedValue === null) {
    return false;
  }

  return JSON.parse(storedValue) !== false;
};

const broadcastInitialState = {
  broadcastKey: undefined,
  broadcastId: undefined,
  broadcastStatus: undefined,
  broadcastKind: undefined,
  broadcastSource: undefined,
  broadcastTitle: "",
  broadcastPictureStatus: BroadcastPictureUploadStatus.SUCCEEDED,
  broadcastPictureUrl: undefined,
  broadcastPictureThumbnailUrl: undefined,
  broadcastVideoEnabled: true,
  broadcastScreenShareEnabled: false,
  broadcastBanDuration: undefined,
  startedPlayingPipsIds: [],
  mobileMediaPermissionsGranted: getInitialMobileMediaPermissionsValue(),
};

const getBroadcastPictureStatus = (state: BroadcastState) =>
  state.broadcastPictureStatus;
const getBroadcastPictureUrl = (state: BroadcastState) =>
  state.broadcastPictureUrl;
const getBroadcastPictureThumbnailUrl = (state: BroadcastState) =>
  state.broadcastPictureThumbnailUrl;
const getBroadcastTitle = (state: BroadcastState) => state.broadcastTitle;

export const localBroadcastSelectors = {
  broadcastKey: (state: BroadcastState) => state.broadcastKey,
  broadcastId: (state: BroadcastState) => state.broadcastId,
  broadcastStatus: (state: BroadcastState) => state.broadcastStatus,
  broadcastBanDuration: (state: BroadcastState) => state.broadcastBanDuration,
  broadcastKind: (state: BroadcastState) => state.broadcastKind,
  broadcastSource: (state: BroadcastState) => state.broadcastSource,
  broadcastTitle: getBroadcastTitle,
  isBroadcastTitleValid: (state: BroadcastState) => {
    const broadcastTitle = getBroadcastTitle(state);

    return (
      !isEmptyOrWhitespace(broadcastTitle) &&
      broadcastTitle.length < BROADCAST_TITLE_MAX_LENGTH
    );
  },
  broadcastPictureStatus: getBroadcastPictureStatus,
  broadcastPictureUrl: getBroadcastPictureUrl,
  broadcastPictureThumbnailUrl: getBroadcastPictureThumbnailUrl,
  isBroadcastPictureValid: (state: BroadcastState) =>
    getBroadcastPictureUrl(state) !== undefined &&
    getBroadcastPictureThumbnailUrl(state) !== undefined &&
    getBroadcastPictureStatus(state) !== BroadcastPictureUploadStatus.STARTED,
  getBroadcastVideoEnabled: (state: BroadcastState) =>
    state.broadcastVideoEnabled,
  isBroadcastScreenShareEnabled: (state: BroadcastState) =>
    state.broadcastScreenShareEnabled,
  getMultiBroadcastInvite: (state: BroadcastState) =>
    state.multiBroadcastInvite,
  getStartedPlayingPipsIds: (state: BroadcastState) =>
    state.startedPlayingPipsIds,
  getMobileMediaPermissionsGranted: (state: BroadcastState) =>
    state.mobileMediaPermissionsGranted,
};

const broadcastReducer = createReducer<BroadcastState>(
  broadcastInitialState,
  (builder) => {
    builder
      .addCase(broadcastActionCreators.broadcastReset, (state) => ({
        ...broadcastInitialState,
        // We persist these
        broadcastPictureUrl: state.broadcastPictureUrl,
        broadcastPictureThumbnailUrl: state.broadcastPictureThumbnailUrl,
        broadcastTitle: state.broadcastTitle,
      }))
      .addCase(
        broadcastActionCreators.broadcastTitleChanged,
        (state, action) => {
          state.broadcastTitle = action.payload;
        }
      )
      .addCase(
        broadcastActionCreators.broadcastPictureUploadStarted,
        (state, action) => {
          const { broadcastPictureUrl, broadcastPictureThumbnailUrl } =
            action.payload;
          Object.assign(state, {
            broadcastPictureStatus: BroadcastPictureUploadStatus.STARTED,
            broadcastPictureUrl,
            broadcastPictureThumbnailUrl,
          });
        }
      )
      .addCase(
        broadcastActionCreators.broadcastPictureUploadSucceeded,
        (state, action) => {
          const { broadcastPictureUrl, broadcastPictureThumbnailUrl } =
            action.payload;
          Object.assign(state, {
            broadcastPictureStatus: BroadcastPictureUploadStatus.SUCCEEDED,
            broadcastPictureUrl,
            broadcastPictureThumbnailUrl,
          });
        }
      )
      .addCase(
        broadcastActionCreators.broadcastPictureUploadFailed,
        (state, action) => {
          const { prevBroadcastPictureUrl, prevBroadcastPictureThumbnailUrl } =
            action.meta;
          Object.assign(state, {
            broadcastPictureStatus: BroadcastPictureUploadStatus.FAILED,
            broadcastPictureUrl: prevBroadcastPictureUrl,
            broadcastPictureThumbnailUrl: prevBroadcastPictureThumbnailUrl,
          });
        }
      )
      .addCase(
        broadcastActionCreators.broadcastInitStarted,
        (state, action) => {
          const { broadcastKind, broadcastSource } = action.payload;
          Object.assign(state, {
            broadcastPictureStatus: BroadcastPictureUploadStatus.SUCCEEDED,
            broadcastStatus: BroadcastStatus.INIT_STARTED,
            broadcastKind,
            broadcastSource,
            broadcastKey: undefined,
            broadcastId: undefined,
          });
        }
      )
      .addCase(broadcastActionCreators.broadcastInitFailed, (state) => {
        state.broadcastStatus = BroadcastStatus.INIT_FAILED;
      })
      .addCase(broadcastActionCreators.broadcastGetInvite, (state, action) => {
        state.multiBroadcastInvite = action.payload;
      })
      .addCase(broadcastActionCreators.broadcastClearInvite, (state) => {
        state.multiBroadcastInvite = undefined;
      })
      .addCase(
        broadcastActionCreators.broadcastInitUserBan,
        (state, action) => {
          Object.assign(state, {
            broadcastBanDuration: action.payload,
            broadcastStatus: BroadcastStatus.INIT_USER_BAN,
          });
        }
      )
      .addCase(broadcastActionCreators.broadcastBadPicture, (state) => {
        state.broadcastStatus = BroadcastStatus.BAD_PICTURE;
      })
      .addCase(
        broadcastActionCreators.broadcastInitSucceeded,
        (state, action) => {
          const { broadcastId, broadcastKey } = action.payload;
          Object.assign(state, {
            broadcastStatus: BroadcastStatus.INIT_SUCCEEDED,
            broadcastId,
            broadcastKey,
          });
        }
      )
      .addCase(broadcastActionCreators.broadcastTerminateStarted, (state) => {
        state.broadcastStatus = BroadcastStatus.TERMINATE_STARTED;
      })
      .addCase(broadcastActionCreators.broadcastTerminateFailed, (state) => {
        state.broadcastStatus = BroadcastStatus.TERMINATE_FAILED;
      })
      .addCase(broadcastActionCreators.broadcastTerminateSucceeded, (state) => {
        state.broadcastStatus = BroadcastStatus.TERMINATE_SUCCEEDED;
      })
      .addCase(broadcastActionCreators.broadcastToggleVideo, (state) => {
        state.broadcastVideoEnabled = !state.broadcastVideoEnabled;
      })
      .addCase(broadcastActionCreators.broadcastToggleScreenShare, (state) => {
        state.broadcastScreenShareEnabled = !state.broadcastScreenShareEnabled;
      })
      .addCase(
        broadcastActionCreators.mobileMediaPermissionsGranted,
        (state, action) => {
          state.mobileMediaPermissionsGranted = action.payload;
        }
      )
      .addCase(broadcastActionCreators.clearStartedPlayingPips, (state) => {
        state.startedPlayingPipsIds = [];
      })
      .addCase(
        broadcastActionCreators.markPipAsStartedPlaying,
        (state, { payload: streamId }) => {
          state.startedPlayingPipsIds.push(streamId);
        }
      )
      .addMatcher(
        (action) => action.type === LOGOUT_END,
        () => broadcastInitialState
      );
  }
);

export { broadcastActionCreators };

export default broadcastReducer;
