export enum InviteSendDetailsState {
  FAILED = "FAILED",
  OK = "OK",
}

export enum InvitationResponseCode {
  BAD_REQUEST = "BAD_REQUEST",
  CANCELED_BY_HOST = "CANCELED_BY_HOST",
  INVALID_STATUS = "INVALID_STATUS",
  NO_VACANT_PLACES = "NO_VACANT_PLACES",
  NOT_SUPPORTED_CLIENT_VERSION = "NOT_SUPPORTED_CLIENT_VERSION",
  STREAM_ENDED = "STREAM_ENDED",
  STREAM_NOT_FOUND = "STREAM_NOT_FOUND",
  SUCCESS = "SUCCESS",
  TRY_AGAIN_LATER = "TRY_AGAIN_LATER",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export enum InvitationType {
  COMPETITION = "COMPETITION",
  LIVE_PARTY = "LIVE_PARTY",
  TOURNAMENT = "TOURNAMENT",
}

export enum InvitationStatus {
  ACCEPTED_BY_USER = "ACCEPTED_BY_USER",
  REJECTED_BY_USER = "REJECTED_BY_USER",
  SENT = "SENT",
}
