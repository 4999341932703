import React, { FC, useCallback, useContext, useMemo } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import {
  useBreakpointMobileLayout,
  useBreakpointPrecise,
  useMakeAlternativeDomainUrl,
} from "src/features/mos/imports/hooks";
import {
  actionCreators,
  getGiftsAlternativeDomainContentSupportEnabled,
  sendGift,
  sendGiftSchedule,
  userSelectors,
} from "src/features/mos/imports/state";
import {
  CoinIconSize,
  GIFT_ORIGIN,
  Gift,
  RealTimeRecommendationsGiftFromType,
} from "src/features/mos/imports/types";
import {
  GiftCoinsView,
  GiftPriceContext,
  GiftRendererContext,
} from "src/features/mos/imports/ui";
import {
  GiftBalance,
  GiftClickParams,
  GiftVariant,
} from "src/features/mos/types";
import styles from "./MosGift.scss";

interface MosGift {
  gift: Gift;
  handleGiftClick?: (giftParams: GiftClickParams) => void;
  position: number;
  typeAbbreviation: string;
  variant?: GiftVariant;
}

const coinSize = {
  [GiftVariant.CHAT]: CoinIconSize.MEDIUM,
  [GiftVariant.STREAM]: CoinIconSize.SMALL,
};

const GIFT_SPECIAL_MEDIA = "MEDIA";

export const MosGift: FC<MosGift> = ({
  gift,
  typeAbbreviation,
  handleGiftClick,
  position,
  variant = GiftVariant.STREAM,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();
  const isMobile = useBreakpointMobileLayout();
  const giftsBalance = useSelector(userSelectors.getGiftsBalance);
  const giftBalance = useMemo(() => {
    if (!gift?.id) {
      return 0;
    }

    return giftsBalance.find(
      (balance: GiftBalance) => balance.gift === gift?.id
    )?.amount;
  }, [gift?.id, giftsBalance]);

  const giftPriceSettings = useContext(GiftPriceContext);
  const { giftGetRenderPosition, giftSetRenderPosition } =
    useContext(GiftRendererContext);

  const coinsViewGift = {
    priceInCredit: gift.priceInCredit,
    withdrawInPoint: gift.withdrawInPoint || 0,
  };

  const onGiftClick = useCallback(() => {
    batch(() => {
      if (variant === GiftVariant.STREAM) {
        dispatch(
          actionCreators.setOptionLastClickedGift({
            giftId: gift.id,
            positionIndex: giftGetRenderPosition(gift.id),
          })
        );
      }
      sendGiftSchedule(() =>
        dispatch(
          // @ts-ignore
          sendGift({
            giftId: gift.id,
            isMobile,
            giftSelectionOrigin: GIFT_ORIGIN.MOS,
            realTimeGiftFromType:
              RealTimeRecommendationsGiftFromType.GIFT_MOODS,
          })
        )
      );
    });
    handleGiftClick?.({ id: gift.id, typeAbbreviation, position });
  }, [gift, typeAbbreviation, position, handleGiftClick]);

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getGiftsAlternativeDomainContentSupportEnabled
  );

  if (!gift || gift?.special === GIFT_SPECIAL_MEDIA) {
    return null;
  }

  giftSetRenderPosition(gift?.id);

  return (
    <div
      className={classnames(styles[variant], styles[breakpoint], styles.gift)}
      data-testid={`mos-item-${typeAbbreviation}-${gift.id}`}
      onClick={onGiftClick}
      role="button"
      tabIndex={0}
    >
      <img
        src={makeAlternativeDomainUrl(gift.icon)}
        className={styles.giftIcon}
        alt="mos"
      />
      <span className={styles.price}>
        <GiftCoinsView
          settingsPrice={giftPriceSettings}
          gift={coinsViewGift}
          giftBalance={giftBalance}
          coinSize={coinSize[variant]}
          showBalance
        />
      </span>
    </div>
  );
};
