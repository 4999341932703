import { IntlFormatters } from "react-intl";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import emitMessageTranslateEvent, {
  TranslateItemIds,
  getScreenNameByConversationId,
} from "chat/analytics/emitMessageTranslateEvent";
import {
  GetConversationParams,
  GetConversationResponse,
  GetConversationsParams,
  GetConversationsResponse,
  GetMessageTranslationResponse,
  ReadConversationParams,
  ReadConversationResponse,
  SendMessageResponse,
  detectMessageLang,
  getConversation,
  getConversations,
  getMessageTranslation as getMessageTranslationApi,
  readConversationMessages,
  sendMessage as sendMessageApi,
} from "chat/api/chat";
import { deleteMessage } from "chat/api/deleteMessage";
import { editMessage } from "chat/api/editMessage";
import { fetchMosV2ForChat } from "chat/api/mos";
import { uploadVideo as uploadVideoApi } from "chat/api/uploadVideo";
import {
  ApiMessageType,
  ParamConversationState,
  TranslateItemType,
} from "chat/enums";
import { uploadImage as uploadImageApi } from "chat/imports/api";
import { FollowSource, ToastType } from "chat/imports/constants";
import { FetcherMetaV2, RootState, addToast, follow } from "chat/imports/state";
import { Nullable } from "chat/imports/types";
import {
  isApiError,
  prepareImageForUpload,
  sharedMessages,
} from "chat/imports/utils";
import { deleteConversation } from "chat/messageRequest/api/messageRequestApi";
import { convertKeysToSnakeCase } from "chat/messageRequest/exports/common";
import { fetchConversationMessageRequest } from "chat/messageRequest/state/asyncAction";
import { DeleteMessageRequestsResponse } from "chat/messageRequest/types";
import { StoredMessage } from "chat/state/reducer";
import chatSelectors from "chat/state/selectors";
import {
  ChatMessageAnalyticsParams,
  FetchMosV2ChatLineupResponse,
  GetMessageTranslationParams,
} from "chat/types";
import isGroupChatId from "chat/utils/isGroupChatId";
import {
  MosError,
  MosV2LineupRequestRejectError,
} from "src/features/mos/types";

export const setCurrentConversationId = createAction<Nullable<string>>(
  "lwc/chat/setCurrentConversationId"
);

export const updateChatMessageBlur = createAction<{
  conversationId: string;
  isBlurred: boolean;
  messageId: number;
}>("lwc/chat/updateMessageBlur");

export const uploadImage = createAsyncThunk<
  {
    height: number;
    mediaId: string;
    thumbnailUrl: string;
    url: string;
    width: number;
  },
  {
    conversationId: string;
    imageUrl: string;
    messageId: string;
    retryId?: string;
  },
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/uploadImage",
  async ({ imageUrl, retryId, conversationId }, api) => {
    if (retryId) {
      const {
        download_url = "",
        thumbnail_url = "",
        media_id = "",
        width = 0,
        height = 0,
        isUploading,
      } = chatSelectors
        .getMessagesByConversationId(api.getState(), conversationId)
        .find((message) => message.id.requestId === retryId)?.media?.[0] || {};

      if (!isUploading) {
        return {
          url: download_url,
          thumbnailUrl: thumbnail_url,
          mediaId: media_id,
          width,
          height,
        };
      }
    }

    try {
      const { width, height, ...imagePreparedForUpload } =
        await prepareImageForUpload(imageUrl);
      const { url, thumbnailUrl, mediaId } = await uploadImageApi(
        imagePreparedForUpload
      );

      return { url, thumbnailUrl, width, height, mediaId };
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const uploadVideo = createAsyncThunk<
  { mediaId: string; thumbnailUrl: string; url: string },
  {
    conversationId: string;
    file: File;
    messageId: string;
    retryId?: string;
  },
  { rejectValue: string; state: RootState }
>("lwc/chat/uploadVideo", async ({ file }, api) => {
  try {
    return await uploadVideoApi(file);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const fetchConversations = createAsyncThunk<
  GetConversationsResponse,
  { isRefreshing?: boolean } & FetcherMetaV2 & GetConversationsParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/fetchConversations",
  async ({ resetOnError, isRefreshing, ...requestParams }, api) => {
    try {
      const requests = await getConversations({
        ...requestParams,
        state: ParamConversationState.CHAT_REQUEST,
      });

      const conversation = await getConversations(requestParams);
      const convertedObject = convertKeysToSnakeCase(
        requests
      ) as GetConversationsResponse;
      const mergedConversations = {
        ...conversation,
        conversations: [
          ...(conversation.conversations || []),
          ...(convertedObject.conversations || []),
        ],
      };

      return mergedConversations;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const fetchConversationsRefresh = createAsyncThunk<
  GetConversationsResponse,
  { isRefreshing?: boolean } & FetcherMetaV2 & GetConversationsParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/fetchConversations",
  async ({ resetOnError, isRefreshing, ...requestParams }, api) => {
    try {
      return await getConversations(requestParams);
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const fetchConversation = createAsyncThunk<
  GetConversationResponse,
  { conversationId: string } & GetConversationParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/fetchConversation",
  async ({ conversationId, ...requestParams }, api) => {
    try {
      const conversation = await getConversation(conversationId, requestParams);
      api.dispatch(
        fetchConversationMessageRequest({ conversationId, ...conversation })
      );

      return conversation;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const readMessages = createAsyncThunk<
  ReadConversationResponse,
  ReadConversationParams,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/readMessages",
  async (params, api) => {
    try {
      return await readConversationMessages(params);
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  },
  {
    condition: (args, api) => {
      const state = api.getState();
      const conversation = chatSelectors.getConversation(
        state,
        args.conversation_id
      );

      return !conversation?.isLoading;
    },
  }
);

export type BaseMessagePayload = {
  conversationId: string;
  formatMessage: IntlFormatters["formatMessage"];
  from: string;
  retryId?: string;
};

export type TextMessagePayload = {
  body: string;
  imageUrl?: never;
};

export type ImageMessagePayload = {
  body?: never;
  imageUrl: string;
};

export const sendTextMessage = createAsyncThunk<
  SendMessageResponse,
  {
    analyticsParams: ChatMessageAnalyticsParams;
  } & BaseMessagePayload &
    TextMessagePayload,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/sendTextMessage",
  async ({ conversationId, body, retryId }, api) => {
    const isGroupChat = isGroupChatId(conversationId);

    try {
      if (!isGroupChat && !retryId) {
        api.dispatch(follow(conversationId, FollowSource.CHAT));
      }

      const response = await sendMessageApi({
        to: [conversationId],
        options: {
          store_message_for_recipient: true,
          store_message_for_sender: true,
        },
        messages: [
          {
            message_type: ApiMessageType.TEXT_MESSAGE,
            body,
          },
        ],
      });

      return response;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

export const sendImageMessage = createAsyncThunk<
  SendMessageResponse,
  {
    analyticsParams: ChatMessageAnalyticsParams;
  } & BaseMessagePayload &
    ImageMessagePayload,
  { rejectValue: string; state: RootState }
>(
  "lwc/chat/sendImageMessage",
  async ({ conversationId, imageUrl, retryId }, api) => {
    const isGroupChat = isGroupChatId(conversationId);

    try {
      if (!isGroupChat) {
        await api.dispatch(follow(conversationId, FollowSource.CHAT));
      }

      const { width, height, thumbnailUrl, url } = await api
        .dispatch(
          uploadImage({
            imageUrl,
            conversationId,
            messageId: api.requestId,
            retryId,
          })
        )
        .unwrap();

      const response = await sendMessageApi({
        to: [conversationId],
        options: {
          store_message_for_recipient: true,
          store_message_for_sender: true,
        },
        messages: [
          {
            message_type: ApiMessageType.IMAGE_MESSAGE,
            media: [
              { width, height, thumbnail_url: thumbnailUrl, download_url: url },
            ],
          },
        ],
      });

      return response;
    } catch (e) {
      const error = isApiError(e) ? e.statusText : (e as Error).message;

      return api.rejectWithValue(error);
    }
  }
);

interface DetectMessageLangResponse {
  language: string;
}

export const getMessageTranslation = createAsyncThunk<
  Partial<DetectMessageLangResponse & GetMessageTranslationResponse>,
  GetMessageTranslationParams,
  { rejectValue: string; state: RootState }
>("lwc/chat/translateMessage", async (params, api) => {
  try {
    const language = await detectMessageLang(params);
    if (language === params.locale) {
      return {
        language,
      };
    }

    const translationResponse = await getMessageTranslationApi(params);

    emitMessageTranslateEvent({
      chatId: params.conversationId,
      translateToLang: params.locale,
      screenName: getScreenNameByConversationId(params.conversationId),
      peerId: params.senderId,
      intValue: params.message.length,
      itemId: TranslateItemIds.OFFLINE_CHAT,
      itemType: TranslateItemType.SERVER,
      translateFromLang: language,
    });

    return {
      ...translationResponse,
      language,
    };
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const setIsTranslated = createAction<{
  conversationId: string;
  isTranslated: boolean;
  messageId: number;
}>("lwc/chat/setIsTranslated");

export const removeConversation = createAsyncThunk<
  DeleteMessageRequestsResponse,
  { conversationId: string },
  { rejectValue: string; state: RootState }
>("lwc/chat/removeConversation", async ({ conversationId }, api) => {
  try {
    return await deleteConversation(conversationId);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

interface FetchMosV2LineupChatParams {
  companionId: string;
}

export const getMosV2ActionForChat = createAsyncThunk<
  FetchMosV2ChatLineupResponse,
  FetchMosV2LineupChatParams,
  MosV2LineupRequestRejectError
>("lwc/mosChatV2/get", async ({ companionId }, { rejectWithValue }) => {
  try {
    const { items, lineupId } = await fetchMosV2ForChat({
      companionId,
    });

    return { items, lineupId };
  } catch (error) {
    if (error instanceof Error) {
      const message = error.message || MosError.SOMETHING_WENT_WRONG;

      return rejectWithValue({ message });
    }

    return rejectWithValue({ message: MosError.SOMETHING_WENT_WRONG });
  }
});

export const deleteChatMessage = createAsyncThunk<
  DeleteMessageRequestsResponse,
  { forAll: boolean; messages: StoredMessage[] },
  { rejectValue: string; state: RootState }
>("lwc/chat/deleteChatMessage", async ({ messages, forAll }, api) => {
  try {
    return await deleteMessage(messages, forAll);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    api.dispatch(
      addToast({
        type: ToastType.REGULAR,
        title: sharedMessages.somethingWrong,
      })
    );

    return api.rejectWithValue(error);
  }
});

export const editChatMessage = createAsyncThunk<
  DeleteMessageRequestsResponse,
  { message: StoredMessage },
  { rejectValue: string; state: RootState }
>("lwc/chat/editChatMessage", async ({ message }, api) => {
  try {
    return await editMessage(message);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;
    api.dispatch(
      addToast({
        type: ToastType.REGULAR,
        title: sharedMessages.somethingWrong,
      })
    );

    return api.rejectWithValue(error);
  }
});

export const setEditingMessageInProgress = createAction<
  Nullable<StoredMessage>
>("lwc/chat/setEditingMessageInProgress");
