import React, { FC, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import MessageMenuOptionsList from "chat/components/MessageMenu/MessageMenuOptionsList/MessageMenuOptionsList";
import { usePopoverPosition } from "chat/hooks/usePopoverPosition";
import { NativePopover } from "chat/imports/components";
import {
  getIsChatDeleteMessageEnabled,
  getIsChatEditMessageEnabled,
} from "chat/soc/chatSoc";
import { StoredMessage } from "chat/state";
import { MessageType } from "chat/types";
import styles from "./MessageActionsMenu.scss";

interface MessageActionsMenuProps {
  isMessageMenuEnabled: boolean;
  isMyMessage: boolean;
  isPopoverOpen: boolean;
  message: StoredMessage;
  setPopoverOpen: (arg: boolean) => void;
}

const MessageActionsMenu: FC<MessageActionsMenuProps> = ({
  children,
  message,
  isMyMessage,
  setPopoverOpen,
  isPopoverOpen,
  isMessageMenuEnabled,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const position = usePopoverPosition({
    anchorEl: ref.current,
    isMyMessage,
    isPopoverOpen,
  });

  const isDeleteMessageEnabled = useSelector(getIsChatDeleteMessageEnabled);
  const isEditMessageEnabled = useSelector(getIsChatEditMessageEnabled);

  const closeMenu = () => setPopoverOpen(false);

  const messageClassNames = classnames({
    [styles.messagePopoverPadding]:
      (isMyMessage &&
        isEditMessageEnabled &&
        message.type === MessageType.TEXT_MESSAGE) ||
      (isEditMessageEnabled && isDeleteMessageEnabled) ||
      isDeleteMessageEnabled,
  });

  const Component = useMemo(
    () => (
      <MessageMenuOptionsList
        message={message}
        isMyMessage={isMyMessage}
        closeMenu={closeMenu}
        ref={ref}
        className={messageClassNames}
      />
    ),
    [message, isMyMessage, isEditMessageEnabled, isDeleteMessageEnabled]
  );

  if (!isMessageMenuEnabled) {
    return <>{children}</>;
  }

  return (
    <div className={styles.wrap}>
      <NativePopover
        isOpen={isPopoverOpen}
        onChange={setPopoverOpen}
        content={Component}
        position={position}
        contentClassName={styles.messagePopover}
      >
        {children}
      </NativePopover>
    </div>
  );
};

export default MessageActionsMenu;
