import invariant from "fbjs/lib/invariant";
import { Candidate } from "broadcast/exports/types";
import { getProxycadorHost, getValidateBroadcastPicture } from "environment";
import { Nullable } from "src/types/common";
import { RichFragment } from "src/types/richFragment/RichFragment";
import { StreamSettings } from "src/types/richFragment/Stream";
import convertNumericStringToNumber from "src/utils/convertNumericStringToNumber";
import { currentTimeMillis } from "src/utils/dateUtils";
import fetch, { urlAndParams } from "./utils/enhancedFetch";

const DEFAULT_PAGE = 0;
const DEFAULT_PAGE_SIZE = 48;
const DEFAULT_LOCALE = "en_US";
const DEFAULT_REGION = "US";
const DEFAULT_MODERATION_LEVEL = 5;

interface FetchParams {
  [key: string]: boolean | number | string;
}

interface ModerationParams {
  accessToPremium: number;
  streamMaxModerationLevel: number;
  streamMaxModerationLevelFullAccess: number;
}

interface PaginationParams {
  pageCount: number;
  pageSize: number;
}

export const fetchHottestFeed = ({
  pageSize = DEFAULT_PAGE_SIZE,
  pageCount = 0,
  sessionId = "",
  sortVersion = "",
  locale = DEFAULT_LOCALE,
  utm = "",
  streamMaxModerationLevel,
  streamMaxModerationLevelFullAccess,
  accessToPremium,
}: FetchParams & ModerationParams & PaginationParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/live/feeds/v1/hottest`,
      {
        pageCount,
        pageSize,
        sessionId,
        version: sortVersion,
        locale,
        utm,
        moderationLevel: streamMaxModerationLevel,
        nsfwModerationLevel: streamMaxModerationLevelFullAccess,
        accessToPremium,
      }
    )
  ).then((resp) => resp.json());

export const fetchLatestFeed = ({
  pageSize = DEFAULT_PAGE_SIZE,
  pageCount = 0,
  sessionId = "",
  sortVersion = "",
  locale = DEFAULT_LOCALE,
  utm = "",
  streamMaxModerationLevel,
  streamMaxModerationLevelFullAccess,
  accessToPremium,
}: FetchParams & ModerationParams & PaginationParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/live/feeds/v1/hotNewAnchor`,
      {
        pageCount,
        pageSize,
        sessionId,
        version: sortVersion,
        locale,
        utm,
        moderationLevel: streamMaxModerationLevel,
        nsfwModerationLevel: streamMaxModerationLevelFullAccess,
        accessToPremium,
      }
    )
  ).then((resp) => resp.json());

interface FetchFeedByTagParams extends PaginationParams, ModerationParams {
  locale: string;
  tag: string;
  utm: string;
}
export const fetchFeedByTag = ({
  tag,
  pageSize = DEFAULT_PAGE_SIZE,
  pageCount = 0,
  locale = DEFAULT_LOCALE,
  utm = "",
  streamMaxModerationLevel,
  streamMaxModerationLevelFullAccess,
  accessToPremium,
}: FetchFeedByTagParams) =>
  fetch(
    urlAndParams(`${getProxycadorHost()}/proxycador/api/live/feeds/v1/byTags`, {
      tag,
      pageCount,
      pageSize,
      locale,
      utm,
      moderationLevel: streamMaxModerationLevel,
      nsfwModerationLevel: streamMaxModerationLevelFullAccess,
      accessToPremium,
    })
  ).then((resp) => resp.json());

interface FetchNearbyFeedParams extends PaginationParams, ModerationParams {
  guest: boolean;
  sessionId: string;
  sortVersion: string;
  utm: string;
}
export const fetchNearbyFeed = ({
  pageSize = DEFAULT_PAGE_SIZE,
  pageCount = 0,
  sessionId = "",
  sortVersion = "",
  guest,
  utm = "",
  streamMaxModerationLevel,
  streamMaxModerationLevelFullAccess,
  accessToPremium,
}: FetchNearbyFeedParams) =>
  fetch(
    urlAndParams(`${getProxycadorHost()}/proxycador/api/live/feeds/v1/nearby`, {
      pageCount,
      pageSize,
      sessionId,
      version: sortVersion,
      guest,
      utm,
      moderationLevel: streamMaxModerationLevel,
      nsfwModerationLevel: streamMaxModerationLevelFullAccess,
      accessToPremium,
    })
  ).then((resp) => resp.json());

interface FetchRecommendationsFeedParams
  extends PaginationParams,
    ModerationParams {
  guest: boolean;
  utm: string;
}
export const fetchRecommendationsFeed = ({
  pageSize = DEFAULT_PAGE_SIZE,
  pageCount = 0,
  guest,
  utm = "",
  streamMaxModerationLevel,
  streamMaxModerationLevelFullAccess,
  accessToPremium,
}: FetchRecommendationsFeedParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/live/feeds/v1/recommendations`,
      {
        pageCount,
        pageSize,
        guest,
        utm,
        moderationLevel: streamMaxModerationLevel,
        nsfwModerationLevel: streamMaxModerationLevelFullAccess,
        accessToPremium,
      }
    )
  ).then((resp) => resp.json());

export const fetchFollowingFeed = ({
  pageSize = DEFAULT_PAGE_SIZE,
  pageCount = 0,
  sortVersion = "",
  utm = "",
  streamMaxModerationLevel,
  streamMaxModerationLevelFullAccess,
  accessToPremium,
}: FetchParams & ModerationParams & PaginationParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/live/feeds/v1/following`,
      {
        pageCount,
        pageSize,
        version: sortVersion,
        utm,
        // this value describes capabilities from XP to BE servers for followers
        // initial value taken from LIVE-637, should e changed in case of changing
        // premium stream prices: coins/subscription
        caps: "eyJjYXBzIjpbIlJFU1RSSUNUSU9OUyJdfQ",
        moderationLevel: streamMaxModerationLevel,
        nsfwModerationLevel: streamMaxModerationLevelFullAccess,
        accessToPremium,
      }
    )
  ).then((resp) => resp.json());

export const fetchFollowingRecommendationsFeed = ({
  sessionId = "",
  region = DEFAULT_REGION,
  locale = DEFAULT_LOCALE,
  pageCount: page = DEFAULT_PAGE,
  tags = "",
  pageSize = DEFAULT_PAGE_SIZE,
  streamMaxModerationLevel,
  streamMaxModerationLevelFullAccess,
  accessToPremium,
}: FetchParams & ModerationParams) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/recommendations/following?tags=${tags}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        sessionId,
        locale,
        region,
        categoryPageSize: pageSize,
        streamPageSize: pageSize,
        page,
        moderationLevel: streamMaxModerationLevel,
        nsfwModerationLevel: streamMaxModerationLevelFullAccess,
        accessToPremium: Boolean(accessToPremium),
      }),
    }
  ).then((resp) => resp.json());

interface FetchListByAccountIdParams {
  accountId: string;
}
export const fetchListByAccountId = ({
  accountId,
}: FetchListByAccountIdParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/live/feeds/v1/byAccountId`,
      { accountId }
    )
  ).then((resp) => resp.json());

interface FetchStreamByEncryptedAccountIdsProps {
  accountIds: string[];
  moderationLevel: number;
}
export const fetchStreamByEncryptedAccountIds = ({
  accountIds,
  moderationLevel = DEFAULT_MODERATION_LEVEL,
}: FetchStreamByEncryptedAccountIdsProps): Promise<AccountToActiveStreamMap> =>
  fetch(
    `${getProxycadorHost()}/stream/social/v2/list/byEncryptedAccountIds?pageSize=${accountIds.length}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        moderationLevel,
        accountIds,
      }),
    }
  ).then((resp) => resp.json());

interface FetchActiveStreamByAccountIdsProps {
  accountIds: string[];
}
export type AccountToActiveStreamMap = Record<string, string>;

export const fetchActiveStreamByAccountIds = ({
  accountIds,
}: FetchActiveStreamByAccountIdsProps): Promise<AccountToActiveStreamMap> =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/v2/activeStreamIds/byAccountIds`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(accountIds),
    }
  ).then((resp) => resp.json());

interface FetchTopBroadcastersParams {
  cursor: string;
  pageSize: number;
  timeframe: string;
}
export const fetchTopBroadcasters = ({
  timeframe,
  cursor = "",
  pageSize = DEFAULT_PAGE_SIZE,
}: FetchTopBroadcastersParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/live/feeds/v1/topBroadcasters`,
      {
        timeframe,
        cursor,
        pageSize,
      }
    )
  ).then((resp) => resp.json());

interface FetchTopGiftersParams {
  enableViewers: boolean;
  pageSize: number;
  streamId: string;
}
export const fetchTopGifters = ({
  streamId,
  pageSize,
  enableViewers,
}: FetchTopGiftersParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/social/v1/${streamId}/topGifters`,
      {
        pageCount: 0,
        pageSize,
        enableViewers,
      }
    )
  ).then((resp) => resp.json());

interface FetchTopGiftersLegacyParams {
  streamId: string;
  topGifterCount: number;
}
export const fetchTopGiftersLegacy = ({
  streamId,
  topGifterCount,
}: FetchTopGiftersLegacyParams) =>
  fetch(
    urlAndParams(`${getProxycadorHost()}/proxycador/api/live/stream/v1/info`, {
      encryptedStreamId: streamId,
      includeTopGifters: true,
      topGifterCount,
    })
  ).then((resp) => resp.json());

interface FetchStreamInfoParams {
  includeEvents: boolean;
  lastEventId: string;
  streamId: string;
}
export const fetchStreamInfo = ({
  streamId,
  includeEvents = false,
  lastEventId = "",
}: FetchStreamInfoParams) => {
  invariant(
    !lastEventId.startsWith("FAKE:"),
    "Should never pass fake event ids to server! (MINT-3241)"
  );

  return fetch(
    urlAndParams(`${getProxycadorHost()}/proxycador/api/live/stream/v1/info`, {
      encryptedStreamId: streamId,
      includeEvents,
      includeStream: true,
      lastEventId,
    })
  ).then((resp) => resp.json());
};

interface SendMessageToStreamParams {
  id: string;
  message: string;
  streamId: string;
  streamerId: string;
  timestamp: number;
}
export const sendMessageToStream = ({
  streamId,
  streamerId,
  message,
  timestamp = currentTimeMillis(),
  id,
}: SendMessageToStreamParams) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/v1/message`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        streamId,
        streamerId,
        text: message,
        timestamp,
        id,
      }),
    }
  )
    .then((resp) => resp.text())
    .then(Number.parseInt);

export const enterStream = (streamId: string, requestId = "") =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/v2/watch`,
      { requestId }
    ),
    {
      method: "POST",
      body: streamId,
    }
  ).then((resp) => resp.json());

export const buyTicket = (streamId: string) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/v1/buyTicket`,
    {
      method: "POST",
      body: streamId,
    }
  ).then((resp) => resp.json());

export const leaveStream = (streamId: string) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/v1/leave`,
    {
      method: "POST",
      body: streamId,
    }
  );

interface FetchNotificationsParams {
  lastNotificationId: string;
  streamId: string;
}
export const fetchNotifications = ({
  streamId,
  lastNotificationId = "",
}: FetchNotificationsParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/v2/notifications`,
      {
        streamId,
        lastNotificationId,
      }
    )
  ).then((resp) => resp.json());

interface FetchRichWindowParams {
  streamId: string;
}
export const fetchRichWindow = ({ streamId }: FetchRichWindowParams) =>
  fetch(`${getProxycadorHost()}/stream/social/v1/web/${streamId}/richWindow`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((resp) => resp.json())
    .then((respData) => convertNumericStringToNumber(respData));

export const fetchRichFragment = (
  streamId: string,
  version: string,
  isRichFragmentCdnEnabled: boolean,
  richFragmentCdnUrl: string
): Promise<RichFragment> => {
  let promise: Promise<Response>;

  const params = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  if (isRichFragmentCdnEnabled && richFragmentCdnUrl) {
    promise = fetch(
      `${richFragmentCdnUrl}/stream/social/v1/web/${streamId}/richFragment?version=${version}`,
      {
        ...params,
        credentials: "omit" as RequestCredentials,
      }
    );
  } else {
    promise = fetch(
      urlAndParams(
        `${getProxycadorHost()}/stream/social/v1/web/${streamId}/richFragment`,
        {
          version,
        }
      ),
      params
    );
  }

  return promise.then((resp) => resp.json());
};

export const fetchBatchStreamStatus = (streamIds: string[]) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/v1/batchStreamStatus`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(streamIds),
    }
  ).then((resp) => resp.json());

export const checkImage = (url: string) => {
  if (!getValidateBroadcastPicture()) {
    return Promise.resolve(true);
  }

  return fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/checkImage?url=${url}`
  )
    .then(({ status }) => status === 200)
    .catch(() => false);
};

// TODO ts
// @ts-ignore
const prepareRestrictionForStream = (gift) =>
  gift ? { ticketPriceInCredits: gift.ticketPriceInCredits } : {};

interface InitStreamParams {
  forceStart: boolean;
  // TODO ts
  gift: unknown;
  landscape: boolean;
  settings: StreamSettings;
  source: string;
  thumbnail: string;
  title: string;
  type: string;
}

export const initStream = ({
  title,
  source,
  type,
  thumbnail,
  gift,
  forceStart = true,
  settings,
  landscape = false,
}: InitStreamParams) =>
  fetch(`${getProxycadorHost()}/proxycador/api/public/v1/live/stream/init`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      type,
      title,
      source,
      thumbnail,
      forceStart,
      settings,
      landscape,
      ...prepareRestrictionForStream(gift),
    }),
  }).then((resp) => resp.json());

interface SetStreamNotificationParams {
  message: string;
}

export const setStreamStartNotification = ({
  message,
}: SetStreamNotificationParams) =>
  fetch(
    `${getProxycadorHost()}/rocketpushdelivery/v1/stream/custom/notification/save`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ message }),
    }
  ).then((resp) => resp.json());

export interface InviteParticipant {
  accountId: string;
  streamId: string;
}

interface GetCandidatesListParams {
  friendsOnly: boolean;
  host: InviteParticipant;
}

interface GetCandidatesListResponse {
  candidates: Nullable<Candidate[]>;
  code: string;
  requestId: string;
}

export const getCandidatesList = ({
  host,
  friendsOnly,
}: GetCandidatesListParams): Promise<GetCandidatesListResponse> =>
  fetch(
    `${getProxycadorHost()}/stream/multiBroadcast/v1/invite/candidateList`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        host,
        friendsOnly,
      }),
    }
  ).then((resp) => resp.json());

interface SendInviteParams {
  guestAccountIdList: string[];
  host: InviteParticipant;
}

interface SendInviteResponse {
  code: string;
  detailsList: {
    accountId: string;
    inviteId: string;
    state: string;
  }[];
  multiBroadcastId: string;
}

export const sendInvites = ({
  host,
  guestAccountIdList,
}: SendInviteParams): Promise<SendInviteResponse> =>
  fetch(`${getProxycadorHost()}/stream/multiBroadcast/v1/invite/send`, {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      host,
      guestAccountIdList,
    }),
  }).then((resp) => resp.json());

interface AcceptInviteParams {
  guest: InviteParticipant;
  host: InviteParticipant;
}

export const acceptInvite = ({ host, guest }: AcceptInviteParams) =>
  fetch(`${getProxycadorHost()}/stream/multiBroadcast/v1/invite/accept`, {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      host,
      guest,
    }),
  }).then((resp) => resp.json());

interface RejectInviteParams {
  guestAccountId: string;
  host: InviteParticipant;
  requestId: string;
}

export const rejectInvite = ({
  host,
  guestAccountId,
  requestId,
}: RejectInviteParams) =>
  fetch(`${getProxycadorHost()}/stream/multiBroadcast/v1/invite/reject`, {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      host,
      guestAccountId,
      requestId,
    }),
  }).then((resp) => resp.json());

export interface AcceptBattleInviteParams {
  id: string;
  recipientStreamId: string;
  requestId: string;
  sourceUuid?: Nullable<string>;
}

export const acceptBattleInvite = async (data: AcceptBattleInviteParams) => {
  if (!data.sourceUuid) {
    delete data.sourceUuid;
  }

  return fetch(`${getProxycadorHost()}/stream/v1/invitations/accept`, {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify(data),
  }).then((resp) => resp.json());
};

export const rejectBattleInvite = (inviteId: string) =>
  fetch(`${getProxycadorHost()}/stream/v1/invitations/reject/${inviteId}`, {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  }).then((resp) => resp.json());

export const leaveLiveParty = (host: InviteParticipant) =>
  fetch(`${getProxycadorHost()}/stream/multiBroadcast/v1/leave`, {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({
      host,
    }),
  }).then((resp) => resp.json());

interface TerminateStreamParams {
  streamId: string;
}
export const terminateStream = ({ streamId }: TerminateStreamParams) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/terminate/${streamId}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }
  ).then((resp) => resp.json());

interface KickViewerFromStreamParams {
  blockViewer: boolean;
  reportViewer: boolean;
  streamId: string;
  viewerId: string;
}
export const kickViewerFromStream = ({
  streamId,
  viewerId,
  reportViewer,
  blockViewer,
}: KickViewerFromStreamParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/kickout/${streamId}`,
      {
        viewerId,
        reportViewer,
        blockViewer,
      }
    ),
    {
      method: "POST",
      body: "",
    }
  ).then((resp) => resp.json());

interface ReportStreamParams {
  reasonMessage: string;
  reasonType: string;
  streamId: string;
  streamerId: string;
}
export const reportStream = ({
  streamId,
  streamerId,
  reasonType,
  reasonMessage,
}: ReportStreamParams) =>
  fetch(
    urlAndParams(
      `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/v1/report`,
      {
        streamId,
        reasonType,
        reasonMessage,
        streamerId,
      }
    ),
    {
      method: "POST",
      body: "",
    }
  ).then((resp) => resp.json());

interface FetchTabsConfigParams {
  locale: string;
}
export const fetchTabsConfig = ({ locale }: FetchTabsConfigParams) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/live/feeds/v1/tabsConfig?locale=${locale}`
  ).then((resp) => resp.json());

interface SetStreamSettingsParams {
  settings: StreamSettings;
  streamId: string;
}
export const setStreamSettings = ({
  streamId,
  settings,
}: SetStreamSettingsParams) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/settings/${streamId}`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(settings),
    }
  ).then((resp) => resp.json());

interface GetStreamSettingsParams {
  streamId: string;
}
export const getStreamSettings = ({ streamId }: GetStreamSettingsParams) =>
  fetch(
    `${getProxycadorHost()}/proxycador/api/public/v1/live/stream/settings/${streamId}`
  ).then((resp) => resp.json());
