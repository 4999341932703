import { PlatformsTypes } from "@analytics/enums";
import {
  QueryParams,
  ReferralInvitation,
  ReferredUsersListResponse,
} from "src/features/referralProgram/common/types";
import { getProxycadorHost } from "src/features/referralProgram/imports/environment";
import { enhancedFetch } from "src/features/referralProgram/imports/utils";

export const loadReferredUsersListRequest = (
  params: QueryParams
): Promise<ReferredUsersListResponse> =>
  enhancedFetch(
    `${getProxycadorHost()}/referral/v2/statistics?${new URLSearchParams(Object.entries(params))}`
  ).then((resp) => resp.json());

export const registerReferralUserRequest = async ({
  registrationReferralType,
  inviterId,
}: ReferralInvitation) => {
  const response = await enhancedFetch(
    `${getProxycadorHost()}/referral/public/v2/registration`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        registrationReferralType,
        inviterId,
        platformType: PlatformsTypes.WEB,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const jsonResp = await response.json();

  if (jsonResp.status !== "SUCCESS") {
    throw new Error(jsonResp.message);
  }
};

export const downloadStatistics = async (
  params: Pick<QueryParams, "order" | "referralType" | "sortBy" | "timeRange">
) => {
  const searchParams = new URLSearchParams(params);

  const response = await enhancedFetch(
    `${getProxycadorHost()}/referral/v2/statistics/export?${searchParams}`
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const filename =
    response.headers
      .get("Content-Disposition")
      ?.split("filename=")[1]
      ?.replace(/"/g, "") ?? "";
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};
