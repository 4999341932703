import { enhancedFetch as fetch } from "chat/imports/utils";
import { StoredMessage } from "chat/state";
import { DeleteMessageRequestsResponse } from "chat/types";
import { getProxycadorHost } from "environment";

export const deleteMessage = (
  messages: StoredMessage[],
  forAll: boolean
): Promise<DeleteMessageRequestsResponse> =>
  fetch(`${getProxycadorHost()}/tc2/v2/messages/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      messages: messages.map((msg) => msg.id),
      delete_for_all: forAll,
    }),
  }).then((resp) => resp.json());
