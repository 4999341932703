import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classnames from "classnames";
import emptyFunction from "fbjs/lib/emptyFunction";
import PropTypes from "prop-types";
import {
  StreamStatus,
  deadMultiBroadcastStreamStatuses,
} from "enums/multiBroadcastStreamStatus";
import { getIsMultiStreamEnabled } from "environment";
import { Breakpoints, DeviceType } from "src/enums";
import loadStreamSettings from "src/state/flows/loadStreamSettings";
import { useMount } from "src/utils/miniReactUse";
import {
  getIsDropDownEnabled,
  getIsSoundWobblerEnabled,
} from "state/SOC/soundControl";
import { getStreamsAlternativeDomainContentSupportEnabled } from "state/abTests";
import { markPipAsStartedPlayingForBroadcaster } from "state/flows/broadcast";
import {
  deviceInfoSelectors,
  profilesCacheSelectors,
  streamsCacheSelectors,
} from "state/selectors";
import Spinner from "ui/common/Spinner";
import { TYPOGRAPHY_TYPE } from "ui/common/typography/Typography";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { useMakeAlternativeDomainUrl } from "ui/hooks/useMakeAlternativeDomainUrl";
import { makeLinkToLiveStream } from "ui/navigation/links";
import Player from "ui/player";
import StreamSettingsContext from "ui/scenes/stream/StreamSettingsContext";
import PauseDisclaimer from "ui/scenes/stream/common/PauseDisclaimer";
import useRetrieveStreamURL from "ui/scenes/stream/common/useRetrieveStreamURL";
import MutedState from "ui/scenes/stream/streamPlayer/MutedState";
import { MemoNsfwPip } from "ui/scenes/stream/streamPlayer/NsfwPip";
import { useOnPlaybackStartError } from "ui/scenes/stream/streamPlayer/useOnPlaybackStartError";
import { useShouldShowNsfwPip } from "ui/scenes/stream/streamPlayer/useShouldShowNsfwPip";
import MuteContext from "../MuteContext";
import EqualizerAnimation from "../common/EqualizerAnimation";
import { useVideoEnabledForStream } from "../common/useVideoEnabled";
import useNetworkReconnectWatchdog from "./useNetworkReconnectWatchdog";
import usePlayerStalledWatchdog from "./usePlayerStalledWatchdog";
import styles from "./PictureInPicturePlayer.scss";
import fadeTransition from "ui/transitions/FadeTransition.scss";

const selectorFactory = (accountId, streamId) => (state) => ({
  basicProfile: profilesCacheSelectors.getBasicProfile(state, accountId),
  deviceType: deviceInfoSelectors.getDeviceType(state),
  isSoundWobblerEnabled: getIsSoundWobblerEnabled(state),
  streamHasVolume: !!streamsCacheSelectors.getStreamVolumeById(state, streamId),
  isDropDownEnabled: getIsDropDownEnabled(state),
});

const PictureInPicturePlayer = ({
  streamId,
  streamURL,
  accountId,
  status,
  className,
  forceDisableHd = true,
  pipsCount = 1,
  onClick,
  style,
}) => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpointPrecise();
  const history = useHistory();
  const videoNodeRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const { muted, setMuted } = useContext(MuteContext);
  const { landscape } = useContext(StreamSettingsContext);
  const {
    basicProfile,
    deviceType,
    streamHasVolume,
    isSoundWobblerEnabled,
    isDropDownEnabled,
  } = useSelector(selectorFactory(accountId, streamId), shallowEqual);
  const isMuted =
    isSoundWobblerEnabled && isDropDownEnabled ? !streamHasVolume : muted;

  const makeAlternativeDomainUrl = useMakeAlternativeDomainUrl(
    getStreamsAlternativeDomainContentSupportEnabled
  );
  const adjustedStreamUrl = makeAlternativeDomainUrl(streamURL);

  useEffect(() => {
    if (muted && videoNodeRef.current) {
      videoNodeRef.current.muted = true;
      videoNodeRef.current.play().catch(emptyFunction);
    }
  }, [muted]);

  const onHlsPlaybackStartError = useOnPlaybackStartError(muted, setMuted);

  const pauseDisclaimerTypography = useMemo(() => {
    if (breakpoint === Breakpoints.TABLET) {
      return TYPOGRAPHY_TYPE.PARAGRAPH5;
    }
    if (
      breakpoint === Breakpoints.MOBILE ||
      breakpoint === Breakpoints.SMALL_MOBILE
    ) {
      return TYPOGRAPHY_TYPE.MINI;
    }

    return pipsCount > 1
      ? TYPOGRAPHY_TYPE.PARAGRAPH5
      : TYPOGRAPHY_TYPE.PARAGRAPH3;
  }, [pipsCount, breakpoint]);

  const onContainerClick = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      } else {
        // using not link but manually pushing to fix firefox picture-in-picture button behavior
        history.replace(makeLinkToLiveStream(streamId));
      }
    },
    [streamId, history, onClick]
  );

  const listeners = useMemo(
    () => ({
      play: () => {
        setIsLoading(false);
        dispatch(markPipAsStartedPlayingForBroadcaster(streamId));
      },
      playing: () => {
        setIsLoading(false);
      },
      waiting: () => {
        setIsLoading(true);
      },
    }),
    [dispatch, streamId]
  );

  const key = [
    usePlayerStalledWatchdog(adjustedStreamUrl, !adjustedStreamUrl, false),
    useNetworkReconnectWatchdog(),
  ].join("-");

  const videoEnabled = useVideoEnabledForStream(streamId);
  const thumbnail = basicProfile?.profilePictureUrl;
  const paused = status === StreamStatus.SUSPENDED;

  useRetrieveStreamURL({ streamId, adjustedStreamUrl });

  /*
  Since fetching the list of streams and until starting to view a specific
  stream, the settings for the stream may have changed. The priority stream will
  receive updated settings when the user starts viewing the stream, while PIP
  streams will not receive updates. To update the settings of PIP streams, we
  fetch PIP stream settings and ignore cached values.
  */
  useMount(() => dispatch(loadStreamSettings({ streamId, ignoreCache: true })));

  const rootClassName = classnames(
    styles.pipWrapper,
    !streamId && styles.disabled,
    className
  );

  const shouldShowNsfwPip = useShouldShowNsfwPip(streamId);

  if (
    !getIsMultiStreamEnabled() ||
    !adjustedStreamUrl ||
    !streamId ||
    deadMultiBroadcastStreamStatuses.includes(status)
  ) {
    return null;
  }

  if (shouldShowNsfwPip) {
    return (
      <MemoNsfwPip
        className={rootClassName}
        pipsCount={pipsCount}
        streamId={streamId}
        style={style}
      />
    );
  }

  return (
    <div
      data-testid={`pip-player-${streamId}`}
      onClick={onContainerClick}
      className={rootClassName}
      style={style}
    >
      <CSSTransition
        in={!paused && isMuted && !isLoading}
        mountOnEnter
        timeout={300}
        unmountOnExit
        classNames={fadeTransition}
      >
        <MutedState />
      </CSSTransition>
      <Player
        ref={videoNodeRef}
        key={key}
        src={adjustedStreamUrl}
        className={classnames(
          styles.player,
          landscape && styles.playerLandscape
        )}
        onHlsPlaybackStartError={onHlsPlaybackStartError}
        muted={isMuted}
        playsInline
        autoPlay={deviceType === DeviceType.IOS}
        paused={paused}
        forceDisableHd={forceDisableHd}
        videoEventListeners={listeners}
      />
      {!videoEnabled && (
        <div
          className={styles.videoDisabledOverlay}
          style={{ backgroundImage: `url(${thumbnail})` }}
        >
          {!paused && (
            <EqualizerAnimation
              className={styles.equalizerContainer}
              showWhenMuted
            />
          )}
        </div>
      )}
      <Spinner
        className={classnames(styles.spinner, {
          [styles.hidden]: paused || !isLoading,
        })}
      />
      <PauseDisclaimer
        visible={paused}
        accountId={accountId}
        className={classnames(styles.pauseDisclaimer, {
          [styles.small]: pipsCount > 2,
        })}
        showIcon={false}
        descriptionClassName={styles.pauseDescription}
        typographyType={pauseDisclaimerTypography}
        pipMode
      />
    </div>
  );
};

PictureInPicturePlayer.propTypes = {
  streamId: PropTypes.string,
  accountId: PropTypes.string,
  streamURL: PropTypes.string,
  status: PropTypes.string,
  forceDisableHd: PropTypes.bool,
  className: PropTypes.string,
  pipsCount: PropTypes.number,
  style: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    gridArea: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default memo(PictureInPicturePlayer);
