import { useCallback } from "react";
import tangoAnalytics from "src/core/analytics";
import {
  Action,
  GiftPlaceholder,
  MosTargetValue,
} from "src/core/analytics/enums";
import {
  EventNames,
  MosEventFields,
  StorageKeys,
} from "src/features/mos/imports/types";

type MosEventFieldParam = Partial<Record<MosEventFields, unknown>>;

export const useMosAnalytics = () => {
  const errorAction = useCallback((params: MosEventFieldParam) => {
    tangoAnalytics.then((analytics) => {
      const analyticsData = analytics.Local.getItem(StorageKeys.ROUTE_DATA);
      analytics.track(EventNames.MOS_RECEIVED_ERROR, {
        [MosEventFields.ACCOUNT_ID]: analyticsData.account_id,
        ...params,
      });
    });
  }, []);

  const giftClickAction = useCallback((params: MosEventFieldParam) => {
    tangoAnalytics.then((analytics) => {
      analytics.track(EventNames.UI_ACTION, {
        [MosEventFields.ACTION]: Action.CLICK,
        [MosEventFields.TARGET]: MosTargetValue.MOS,
        [MosEventFields.GIFT_PLACEHOLDER]: GiftPlaceholder.MOS_V2,
        ...params,
      });
    });
  }, []);

  const startScrollAction = useCallback((params: MosEventFieldParam) => {
    tangoAnalytics.then((analytics) => {
      analytics.track(EventNames.UI_ACTION, {
        [MosEventFields.ACTION]: Action.SCROLL,
        [MosEventFields.GIFT_PLACEHOLDER]: GiftPlaceholder.MOS_V2,
        ...params,
      });
    });
  }, []);

  const endScrollAction = useCallback((params: MosEventFieldParam) => {
    tangoAnalytics.then((analytics) => {
      const analyticsData = analytics.Local.getItem(StorageKeys.ROUTE_DATA);
      analytics.track(EventNames.MOS_DISPLAYED, {
        [MosEventFields.ACCOUNT_ID]: analyticsData.account_id,
        ...params,
      });
    });
  }, []);

  return { giftClickAction, startScrollAction, endScrollAction, errorAction };
};
