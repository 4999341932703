import { useLayoutEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState, getMoodsV2ForChatEnabled } from "chat/imports/state";
import chatSelectors from "chat/state/selectors";
import { PopoverPosition } from "src/enums";

const POSITION = {
  BOTTOM: "BOTTOM",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  TOP: "TOP",
};

const selector = (state: RootState) => ({
  isMOSinChatEnabled: getMoodsV2ForChatEnabled(state),
  isMessageEditingActive: chatSelectors.getEditingMessageInProgress(state),
});

export const usePopoverPosition = ({
  anchorEl,
  isMyMessage,
  isPopoverOpen,
}: {
  anchorEl: HTMLElement | null;
  isMyMessage: boolean;
  isPopoverOpen: boolean;
}) => {
  const [direction, setDirection] = useState(POSITION.BOTTOM);

  const { isMOSinChatEnabled, isMessageEditingActive } = useSelector(
    selector,
    shallowEqual
  );

  const side = isMyMessage ? POSITION.RIGHT : POSITION.LEFT;

  const MOS_HEIGHT = isMOSinChatEnabled ? 64 : 0;
  const CONVERSATION_BAR_HEIGHT = 105;
  const MARGIN = 5;
  const MESSAGE_EDITING_PANEL_HEIGHT = isMessageEditingActive ? 55 : 0;
  const BOTTOM_OFFSET =
    CONVERSATION_BAR_HEIGHT +
    MOS_HEIGHT +
    MARGIN +
    MESSAGE_EDITING_PANEL_HEIGHT;

  useLayoutEffect(() => {
    if (!anchorEl) {
      return;
    }

    const { y, height } = anchorEl.getBoundingClientRect();
    const { innerHeight } = window;

    if (innerHeight - y - height < BOTTOM_OFFSET) {
      setDirection(POSITION.TOP);
    }
  }, [isPopoverOpen]);

  return PopoverPosition[
    `${direction}_${side}` as keyof typeof PopoverPosition
  ];
};
