import {
  type PayloadAction,
  SerializedError,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from "@reduxjs/toolkit";
import { emitEvent } from "@analytics/emit";
import { EventFields, EventNames } from "@analytics/enums";
import { isApiError } from "api/utils/enhancedFetch";
import {
  loadReferredUsersListRequest,
  registerReferralUserRequest,
} from "src/features/referralProgram/api/referralUsersApi";
import {
  DEFAULT_PAGE_SIZE,
  TOP_USERS_NUM_MOBILE,
} from "src/features/referralProgram/common/constants";
import {
  AgencyLevel,
  Currency,
  ReferralType,
  ReferredUsersOrder,
  ReferredUsersSortBy,
  TimeRange,
  UserActivity,
} from "src/features/referralProgram/common/enums";
import {
  ActiveTear,
  QueryParams,
  ReferralInvitation,
  ReferredUser,
  ReferredUsersListResponse,
  ReferredUsersMetaData,
  Tier,
} from "src/features/referralProgram/common/types";
import { batchLoadProfiles } from "src/features/referralProgram/imports/state";
import { getProjectedTierIndex } from "src/features/referralProgram/utils/getProjectedTierIndex";
import { Nullable } from "src/types/common";
import { RootState } from "state/delegate";
import { userSelectors } from "state/selectors";
import { ApplicationThunk } from "state/types";

export const loadReferralUsersList = createAsyncThunk<
  ReferredUsersListResponse,
  void,
  { rejectValue: string; state: RootState }
>("lwc/referredUsers/loadReferralUsersList", async (payload, api) => {
  try {
    const userList = await loadReferredUsersListRequest(
      api.getState().referralProgram.queryParams
    );
    const accountIds = userList.data.map(({ accountId }) => accountId);

    api.dispatch(
      batchLoadProfiles({
        ids: accountIds,
        loadOnlyIfMissing: true,
        params: { basic: true, detail: false, live: true, follow: false },
      })
    );

    return userList;
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const registerReferralUser = createAsyncThunk<
  void,
  ReferralInvitation,
  { rejectValue: string; state: RootState }
>("lwc/referredUsers/registerReferralUser", async (referralInvitation, api) => {
  try {
    await registerReferralUserRequest(referralInvitation);
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const referralInvitationKey = "referralInvitation";

export const INITIAL_QUERY_PARAMS: QueryParams = {
  referralType: ReferralType.CREATORS,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  order: ReferredUsersOrder.DESC,
  sortBy: ReferredUsersSortBy.EARNED_POINTS,
  activity: UserActivity.ANY,
  timeRange: TimeRange.CURRENT_MONTH,
  nameFilter: "",
};

export interface ReferralProgramState {
  currency: Currency;
  error: Nullable<SerializedError>;
  loading: boolean;
  meta: ReferredUsersMetaData;
  queryParams: QueryParams;
  [referralInvitationKey]: Nullable<ReferralInvitation>;
  referredUsers: Array<ReferredUser>;
  selectedTierIndex: number;
  tier: ActiveTear;
  tiers: Tier[];
  topReferredUsers: Array<ReferredUser>;
}

export const initialState: ReferralProgramState = {
  referredUsers: [],
  topReferredUsers: [],
  meta: {
    pageCount: 1,
    totalEarnedPoints: 0,
    totalNewUsers: 0,
    totalPoints: 0,
    totalRedeemPoints: 0,
    totalUsers: 0,
    totalStreamedDays: 0,
    totalStreamedSeconds: 0,
    totalUsd: 0,
    totalEarnedUsd: 0,
    totalRedeemUsd: 0,
  },
  tiers: [],
  tier: {
    currentMonthRevenuePoints: 0,
    currentTier: AgencyLevel.ROOKIE,
    projectedNextTier: AgencyLevel.BRONZE,
  },
  loading: false,
  error: null,
  queryParams: INITIAL_QUERY_PARAMS,
  currency: Currency.DIAMONDS,
  selectedTierIndex: 0,
  referralInvitation: null,
};

export const persistConfig = {
  whitelist: [referralInvitationKey],
};

const slice = createSlice({
  name: "referralProgram",
  initialState,
  reducers: {
    setQueryParams(state, action: PayloadAction<Partial<QueryParams>>) {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setSelectedTierIndex(state, action: PayloadAction<number>) {
      state.selectedTierIndex = action.payload;
    },
    setCurrency(state, action: PayloadAction<Currency>) {
      state.currency = action.payload;
    },
    setReferralInvitation(state, action: PayloadAction<ReferralInvitation>) {
      state[referralInvitationKey] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadReferralUsersList.fulfilled, (state, action) => {
      state.loading = false;
      state.referredUsers = action.payload.data;
      state.topReferredUsers = action.payload.data.slice(
        0,
        TOP_USERS_NUM_MOBILE
      );
      state.meta = action.payload.meta;

      if (action.payload.meta.tiers?.length && action.payload.meta.tier) {
        state.tiers = action.payload.meta.tiers;
        state.tier = action.payload.meta.tier;
        state.selectedTierIndex = getProjectedTierIndex(action.payload.meta);
      }
    });
    builder.addCase(loadReferralUsersList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loadReferralUsersList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addMatcher(
      isAnyOf(registerReferralUser.fulfilled, registerReferralUser.rejected),
      (state) => {
        state[referralInvitationKey] = null;
      }
    );
  },
});

export const {
  setQueryParams,
  setSelectedTierIndex,
  setCurrency,
  setReferralInvitation,
} = slice.actions;

export const referralProgramReducer = slice.reducer;

export const changeQueryParams =
  (queryParams: Partial<QueryParams>): ApplicationThunk =>
  (dispatch) => {
    dispatch(setQueryParams(queryParams));
    dispatch(loadReferralUsersList());
  };

export const resetQueryParams = (): ApplicationThunk => (dispatch) => {
  dispatch(changeQueryParams(INITIAL_QUERY_PARAMS));
};

export const attemptRegisterReferralUser =
  (): ApplicationThunk => (dispatch, getState) => {
    const state = getState();
    const referralInvitation = state.referralProgram.referralInvitation;

    if (referralInvitation) {
      dispatch(registerReferralUser(referralInvitation));

      emitEvent(EventNames.OPEN_DEEPLINK, {
        [EventFields.RECIPIENT_ACCOUNT_ID]: userSelectors.getMyAccountId(state),
        [EventFields.TARGET]:
          referralInvitation.registrationReferralType.toLowerCase(),
        [EventFields.LOGGED_IN_ACCOUNT_ID]: referralInvitation.inviterId,
        [EventFields.ACTION]: "referral_program",
      });
    }
  };
