import React, { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import MessageMenuOptionBase from "chat/components/MessageMenu/MessageMenuOptions/MessageMenuOptionBase";
import { MESSAGE_POPOVER_OPTIONS } from "chat/constants/messageMenuOptionsConfig";
import { StoredMessage, setEditingMessageInProgress } from "chat/state";
import { MessageType } from "chat/types";

export interface MessageMenuOptionProps {
  closeMenu: VoidFunction;
  isMyMessage: boolean;
  message: StoredMessage;
}

const MessageMenuEditOption: FC<MessageMenuOptionProps> = ({
  message,
  closeMenu,
  isMyMessage,
}) => {
  const dispatch = useDispatch();
  const isTextMessage = message.type === MessageType.TEXT_MESSAGE;

  const onEditHandler = useCallback(() => {
    dispatch(setEditingMessageInProgress(message));
    closeMenu();
  }, [dispatch, message]);

  if (!isMyMessage || !isTextMessage) {
    return null;
  }

  return (
    <MessageMenuOptionBase
      option={MESSAGE_POPOVER_OPTIONS.edit}
      onClick={onEditHandler}
    />
  );
};

export default MessageMenuEditOption;
