import React, { FC, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { batch, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import lightFormat from "date-fns/lightFormat";
import roundToPrecision from "round-to-precision";
import {
  SCREEN_NAME,
  SIGNIN_TARGET,
  emitUiAction,
} from "src/features/signin/imports/analytics";
import { Breakpoints, LoginProvider } from "src/features/signin/imports/enums";
import {
  deviceInfoSelectors,
  loginSelectors,
} from "src/features/signin/imports/state";
import { VoidCallback } from "src/features/signin/imports/types";
import {
  AppStoreLink,
  Button,
  ButtonSize,
  ButtonVariant,
  GooglePlayLink,
  TYPOGRAPHY_TYPE,
  Typography,
  sharedMessages,
  useBreakpointPrecise,
  useCountdown,
} from "src/features/signin/imports/ui";
import { getAppDeeplink } from "src/features/signin/imports/utils";
import { loginWithProvider } from "src/features/signin/state/flows/login";
import {
  askForSms,
  phoneLoginResendCode,
} from "src/features/signin/state/login/actionCreators";
import styles from "./ResendCode.scss";

const roundTo1000 = roundToPrecision(1000, Number);

interface ResendCodeProps {
  onLoginSuccess?: VoidCallback;
}

const ResendCode: FC<ResendCodeProps> = ({ onLoginSuccess }) => {
  const dispatch = useDispatch();

  const { phoneNumber, allowOnlySmsValidation, verificationWithoutSMS } =
    useSelector(loginSelectors.getPhoneNumberAuthenticationState);
  const resendAllowedTimestamp = useSelector((state) =>
    loginSelectors.getResendAllowedTimestamp(state, phoneNumber)
  );
  const deviceType = useSelector(deviceInfoSelectors.getDeviceType);

  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const msBeforeResend = useCountdown(resendAllowedTimestamp, 500);
  const roundedMsBeforeResend = roundTo1000(msBeforeResend);

  const resendCode = useCallback(() => {
    emitUiAction({
      target: SIGNIN_TARGET.SEND_CODE_AGAIN,
      tango_screen: {
        reportedName: SCREEN_NAME.REGISTRATION_SIGN_IN,
      },
    });

    batch(() => {
      dispatch(phoneLoginResendCode());
      dispatch(
        // TODO: move login.js to TypeScript in https://tango-me.atlassian.net/browse/WEB-6884
        // @ts-ignore
        loginWithProvider({
          provider: LoginProvider.TANGO_PHONE_NUMBER,
          onLoginSuccess,
        })
      );
    });
  }, [dispatch, onLoginSuccess]);

  const sendCodeAsSms = useCallback(() => {
    batch(() => {
      dispatch(askForSms());
      dispatch(phoneLoginResendCode());
      dispatch(
        // TODO: move login.js to TypeScript in https://tango-me.atlassian.net/browse/WEB-6884
        // @ts-ignore
        loginWithProvider({
          provider: LoginProvider.TANGO_PHONE_NUMBER,
          onLoginSuccess,
        })
      );
    });
  }, [dispatch, onLoginSuccess]);

  /* eslint-disable no-nested-ternary */
  return allowOnlySmsValidation ? (
    msBeforeResend > 0 ? (
      <div
        className={classnames(styles.resendCodeCountdown, styles[breakpoint])}
      >
        <Typography
          as="span"
          data-testid="resend-code-countdown"
          type={TYPOGRAPHY_TYPE.PARAGRAPH5}
        >
          <FormattedMessage
            id="modal.login.countdown"
            defaultMessage="Resend code in {time}"
            values={{
              time: (
                <Typography
                  as="span"
                  data-testid="resend-code-countdown-time"
                  type={TYPOGRAPHY_TYPE.HEADLINE5}
                >
                  {lightFormat(roundedMsBeforeResend, "mm:ss")}
                </Typography>
              ),
            }}
          />
        </Typography>
      </div>
    ) : (
      <Button
        size={ButtonSize.MEDIUM_32}
        variant={ButtonVariant.TERTIARY}
        className={classnames(styles.resendButton, styles[breakpoint])}
        onClick={resendCode}
        data-testid="send-sms-again-button"
      >
        <FormattedMessage
          id="modal.login.resendCode"
          defaultMessage="Send code again"
        />
      </Button>
    )
  ) : (
    <div
      className={classnames(styles.accessToAppContainer, styles[breakpoint])}
    >
      <Typography
        className={classnames(styles.accessToApp, styles[breakpoint])}
        data-testid="access-to-app"
        as="div"
        type={TYPOGRAPHY_TYPE.PARAGRAPH5}
      >
        <FormattedMessage
          id="modal.login.noAccessToApp1"
          defaultMessage="Don’t have Tango app?"
        />
      </Typography>
      {verificationWithoutSMS ? (
        <>
          {isDesktop ? (
            <div className={styles.iconsWrapper}>
              <AppStoreLink className={styles.appIcon} />
              <GooglePlayLink className={styles.appIcon} />
            </div>
          ) : (
            <Button
              size={ButtonSize.BIG_48}
              variant={ButtonVariant.PRIMARY}
              className={styles.linkToStore}
              external
              to={getAppDeeplink(deviceType)}
            >
              <FormattedMessage {...sharedMessages.installTheApp} />
            </Button>
          )}
        </>
      ) : (
        <Button
          size={ButtonSize.MEDIUM_32}
          variant={ButtonVariant.TERTIARY}
          className={classnames(styles.resendCode, styles[breakpoint])}
          onClick={sendCodeAsSms}
          data-testid="send-sms-instead-button"
        >
          <FormattedMessage
            id="modal.login.send.sms.instead"
            defaultMessage="Send SMS instead"
          />
        </Button>
      )}
    </div>
  );
};

export default ResendCode;
