import { defineMessages } from "react-intl";
import { MessageType } from "chat/types";

export const messages = defineMessages({
  header: {
    id: "chat.message.kyc.validation.head",
    defaultMessage: "Hi, {userName}!",
  },
  text: {
    id: "chat.message.kyc.validation.body",
    defaultMessage:
      "This is Tango Customer Care. We kindly ask you to pass verification to confirm your identity. Once reviewed and verified by our team — your account profile will get an official badge!",
  },
  instruction: {
    id: "chat.message.kyc.validation.instruction",
    defaultMessage: "Step-by-step guide can be found here: {link}.",
  },
  linkText: {
    id: "chat.message.kyc.validation.link",
    defaultMessage: "how to verify your account",
  },
  buttonText: {
    id: "redeem_verify_word",
    defaultMessage: "Verify",
  },
  title: {
    id: "home.v2.chat",
    defaultMessage: "Chat",
  },
  helpText: {
    id: "chat.empty-panel.help-text",
    defaultMessage: "Your chat activities will be shown here",
  },
  placeholder: {
    id: "chat.current-conversation.input-placeholder.dots",
    defaultMessage: "Message...",
  },
  deleteMessage: {
    id: "chat.message.menu.delete",
    defaultMessage: "Delete Message",
  },
  deleteMessageOnlyForMe: {
    id: "chat.message.menu.delete.for.me",
    defaultMessage: "Delete message only for me",
  },
  confirmDelete: {
    id: "chat.message.menu.confirm.delete",
    defaultMessage: "Are you sure you want to delete this message?",
  },
  confirmForAll: {
    id: "chat.message.menu.confirm.for.all",
    defaultMessage: "Delete for all",
  },
  edited: {
    id: "chat.message.edited.status",
    defaultMessage: "edited",
  },
  editing: {
    id: "chat.message.editing.status",
    defaultMessage: "Editing",
  },
});

export const previewMessages = defineMessages({
  [MessageType.IMAGE_MESSAGE]: {
    id: "chat.preview.image",
    defaultMessage: "Photo",
  },
  [MessageType.VIDEO_MESSAGE]: {
    id: "chat.preview.video",
    defaultMessage: "Video",
  },
  [MessageType.AUDIO_MESSAGE]: {
    id: "chat.preview.audio",
    defaultMessage: "Audio message",
  },
  [MessageType.GIFT_IN_CHAT]: {
    id: "chat.preview.gift",
    defaultMessage: "Gift!",
  },
  [MessageType.PROFILE_MESSAGE]: {
    id: "chat.message.profile.preview",
    defaultMessage: "Profile",
  },
  [MessageType.LIVE_STREAM]: {
    id: "chat.message.live.preview",
    defaultMessage: "Live 1 to 1",
  },
  [MessageType.PREMIUM_MESSAGE_SHARED]: {
    id: "chat.preview.premiumMessage",
    defaultMessage: "Premium Message",
  },
  [MessageType.VIDEO_PTT]: {
    id: "tc_videomail_default_title_read",
    defaultMessage: "Video Message",
  },
  [MessageType.GIF_MESSAGE]: {
    id: "gif.message",
    defaultMessage: "GIF",
  },
  specificGiftWithPrice: {
    id: "gift.name.gift.price.coins",
    defaultMessage: "{giftName} ({giftPrice} coins)",
  },
});

export const groupChatMessages = defineMessages({
  [MessageType.GROUP_NAME_CHANGE]: {
    id: "chat.conversations.group.rename",
    defaultMessage: `{name} changed the group name to {groupName}`,
  },
  [MessageType.GROUP_MEMBER_JOIN]: {
    id: "chat.group.event.text.joined",
    defaultMessage: "{inviterName} added {invitedNames}",
  },
  joinByAcceptingInvite: {
    id: "chat.conversations.group.join.self",
    defaultMessage: "{inviterName} has joined the group chat",
  },
  [MessageType.GROUP_MEMBER_LEAVE]: {
    id: "chat.conversations.group.leave",
    defaultMessage: "{name} has left the chat",
  },
});

export const chatMessages = defineMessages({
  youSavedVideo: {
    id: "you_saved_video",
    defaultMessage: "You saved a video",
  },
  someoneSavedVideo: {
    id: "someone.saved.a.video",
    defaultMessage: "{name} saved a video",
  },
  youSavedPhoto: {
    id: "you_saved_photo",
    defaultMessage: "You saved a photo",
  },
  someoneSavedPhoto: {
    id: "someone.saved.a.photo",
    defaultMessage: "{name} saved a photo",
  },
  incomingCall: {
    id: "incoming_call",
    defaultMessage: "Incoming Call",
  },
  outgoingCall: {
    id: "outgoing_call",
    defaultMessage: "Outgoing Call",
  },
  missedCall: {
    id: "missed.call",
    defaultMessage: "Missed Call",
  },
  GIF: {
    id: "gif.message",
    defaultMessage: "GIF",
  },
});

export const unsupportedMessages = defineMessages({
  default: {
    id: "chat.unsupported-message",
    defaultMessage:
      "Currently this message is not supported by the web version of Tango. Please check it on your phone.{br}You can install Tango app from {appStoreLink} and {googlePlayLink}",
  },
  default_short: {
    id: "chat.unsupported-message-short",
    defaultMessage:
      "Currently this message is not supported by the web version of Tango.",
  },
});
