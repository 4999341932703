/* eslint-disable no-restricted-imports */
import {
  ACME_RECEIVED,
  GIFT_PLAYED,
  GIFT_SENT,
  GIFTS_LIST_END_FETCH,
  GIFTS_LOADED_SINGLE_GIFT,
  LIVE_RICH_NOTIFICATION_RECEIVED,
  VIEWER_SESSION_CLEAR_QUEUED_GIFT_EVENT,
  VIEWER_SESSION_CLEAR_TOP_GIFTER_MESSAGE_EVENT,
  VIEWER_SESSION_CLOSE_PIP_PLAYER,
  VIEWER_SESSION_FORCE_EVENTS_MAX_LENGTH,
  VIEWER_SESSION_GIFT_EVENT_SOUND_PROCESSED,
  VIEWER_SESSION_LIVE_CHAT_SET_IS_TRANSLATED,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_BEGIN,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_END,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_ERROR,
  VIEWER_SESSION_PREMIUM_MB_START_WATCH,
  VIEWER_SESSION_RESET,
  VIEWER_SESSION_RESET_COMPETITION,
  VIEWER_SESSION_SET_CLEAR_VIEW,
  VIEWER_SESSION_SET_TOP_GIFTERS_REQUEST_LIMIT,
} from "state/actionTypes";
import competitionEventsSliceReducer, {
  localCompetitionSelectors,
} from "state/tree/viewerSession/competitionEventsSliceReducer";
import mediaGiftsReducer, {
  selectors as mediaGiftsSelectors,
} from "state/tree/viewerSession/mediaGiftsSliceReducer";
import stickersSliceReducer, {
  localStickersSelectors,
} from "state/tree/viewerSession/stickersSliceReducer";
import streamSettingsSliceReducer, {
  localStreamSettingsSelectors,
} from "state/tree/viewerSession/streamSettingsSliceReducer";
import combineReducersWithFullState from "./utils/combineReducersWithFullState";
import delegateSelectors from "./utils/delegateSelectors";
import { getStreamId } from "./viewerSession/commonSelectors";
import eventsSliceReducer, {
  selectors as eventsSelectors,
} from "./viewerSession/eventsSliceReducer";
import giftsDisplayQueueSliceReducer, {
  selectors as giftsDisplayQueueSelectors,
} from "./viewerSession/giftsDisplayQueueSliceReducer";
import multiBroadcastSliceReducer, {
  localMultiBroadcastSelectors,
} from "./viewerSession/multiBroadcastSliceReducer";
import pullEventsSliceReducer, {
  selectors as pullEventsSelectors,
} from "./viewerSession/pullEventsSliceReducer";
import streamDetailsSliceReducer, {
  selectors as streamDetailsSelectors,
} from "./viewerSession/streamDetailsSliceReducer";
import streamIdSliceReducer from "./viewerSession/streamIdSliceReducer";
import topGifterMessageSliceReducer, {
  selectors as topGifterMessageSelectors,
} from "./viewerSession/topGifterMessageSliceReducer";
import topGiftersSliceReducer, {
  selectors as topGiftersSelectors,
} from "./viewerSession/topGiftersSliceReducer";
import viewerStatusSliceReducer, {
  selectors as viewerStatusSelectors,
} from "./viewerSession/viewerStatusSliceReducer";

const comboReducer = combineReducersWithFullState({
  viewerStatus: viewerStatusSliceReducer,
  streamId: streamIdSliceReducer,
  topGifters: topGiftersSliceReducer,
  events: eventsSliceReducer,
  details: streamDetailsSliceReducer,
  giftsDisplayQueue: giftsDisplayQueueSliceReducer,
  topGifterMessage: topGifterMessageSliceReducer,
  pullEvents: pullEventsSliceReducer,
  multibroadcastStreams: multiBroadcastSliceReducer,
  competitionStreams: competitionEventsSliceReducer,
  mediaGifts: mediaGiftsReducer,
  stickers: stickersSliceReducer,
  streamSettings: streamSettingsSliceReducer,
});

export default (state = comboReducer(undefined, {}), action) => {
  switch (action.type) {
    case VIEWER_SESSION_RESET:
    case GIFTS_LIST_END_FETCH:
    case GIFTS_LOADED_SINGLE_GIFT:
    case VIEWER_SESSION_CLEAR_QUEUED_GIFT_EVENT:
    case VIEWER_SESSION_CLEAR_TOP_GIFTER_MESSAGE_EVENT:
    case VIEWER_SESSION_FORCE_EVENTS_MAX_LENGTH:
    case VIEWER_SESSION_SET_TOP_GIFTERS_REQUEST_LIMIT:
    case VIEWER_SESSION_CLOSE_PIP_PLAYER:
    case VIEWER_SESSION_RESET_COMPETITION:
    case VIEWER_SESSION_GIFT_EVENT_SOUND_PROCESSED:
    case VIEWER_SESSION_LIVE_CHAT_TRANSLATION_BEGIN:
    case VIEWER_SESSION_LIVE_CHAT_TRANSLATION_END:
    case VIEWER_SESSION_LIVE_CHAT_TRANSLATION_ERROR:
    case VIEWER_SESSION_LIVE_CHAT_SET_IS_TRANSLATED:
    case VIEWER_SESSION_SET_CLEAR_VIEW:
    case VIEWER_SESSION_PREMIUM_MB_START_WATCH: {
      return comboReducer(state, action);
    }
    case LIVE_RICH_NOTIFICATION_RECEIVED: {
      if (action.payload.streamId === state.streamId) {
        return comboReducer(state, action);
      }

      return state;
    }
    case ACME_RECEIVED: {
      const { serviceName, serviceIdentifier } = action.payload;
      if (
        serviceName === "stream" &&
        serviceIdentifier.split(":")[1] === state.streamId
      ) {
        return comboReducer(state, action);
      }

      return state;
    }
    case GIFT_SENT: {
      if (action.payload.streamId === state.streamId) {
        return comboReducer(state, action);
      }

      return state;
    }
    case GIFT_PLAYED: {
      return comboReducer(state, action);
    }
    default: {
      if (
        action.meta &&
        action.meta.streamId === state.streamId &&
        state.streamId
      ) {
        return comboReducer(state, action);
      }

      return state;
    }
  }
};

export const selectors = {
  getStreamId,
  ...delegateSelectors(topGiftersSelectors, (state) => state.topGifters),
  ...delegateSelectors(mediaGiftsSelectors, (state) => state.mediaGifts),
  ...delegateSelectors(viewerStatusSelectors, (state) => state.viewerStatus),
  ...delegateSelectors(streamDetailsSelectors, (state) => state.details),
  ...delegateSelectors(eventsSelectors, (state) => state.events),
  ...delegateSelectors(
    giftsDisplayQueueSelectors,
    (state) => state.giftsDisplayQueue
  ),
  ...delegateSelectors(
    topGifterMessageSelectors,
    (state) => state.topGifterMessage
  ),
  ...delegateSelectors(pullEventsSelectors, (state) => state.pullEvents),
  ...delegateSelectors(
    localMultiBroadcastSelectors,
    (state) => state.multibroadcastStreams
  ),
  ...delegateSelectors(
    localCompetitionSelectors,
    (state) => state.competitionStreams
  ),

  ...delegateSelectors(localStickersSelectors, (state) => state.stickers),
  ...delegateSelectors(
    localStreamSettingsSelectors,
    (state) => state.streamSettings
  ),
};
