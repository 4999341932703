import React, { TouchEvent, UIEvent, forwardRef } from "react";
import { MosGift } from "src/features/mos/components/MosGift/MosGift";
import { MOS_LINEUP_ID } from "src/features/mos/constants";
import {
  GiftClickParams,
  GiftVariant,
  MosUiItem,
} from "src/features/mos/types";

interface MosLineup {
  containerClassName?: string;
  handleGiftClick?: (giftParams: GiftClickParams) => void;
  mosItems: MosUiItem[];
  onScroll: (e: UIEvent<HTMLDivElement>) => void;
  variant?: GiftVariant;
}
const preventPropagation = (event: TouchEvent<HTMLDivElement>) => {
  event?.stopPropagation?.();
};

export const MosLineup = forwardRef<HTMLDivElement, MosLineup>(
  (
    { containerClassName, mosItems, handleGiftClick, onScroll, variant },
    ref
  ) => {
    if (!mosItems.length) {
      return null;
    }

    return (
      <div
        id={MOS_LINEUP_ID}
        className={containerClassName}
        ref={ref}
        onScroll={onScroll}
        data-testid="mos-lineup"
        onTouchStart={preventPropagation}
        onTouchMove={preventPropagation}
        onTouchEnd={preventPropagation}
      >
        {mosItems.map(({ content: { gift }, typeAbbreviation }, index) => (
          <MosGift
            key={gift.id}
            gift={gift}
            typeAbbreviation={typeAbbreviation}
            handleGiftClick={handleGiftClick}
            variant={variant}
            position={index}
          />
        ))}
      </div>
    );
  }
);

MosLineup.displayName = "MosLineup";
