import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Nullable } from "src/types/common";
import {
  getStreamMaxModerationLevelFullAccess,
  getStreamModerationLevelWhenUndefinedInResponse,
} from "state/abTests";
import { RootState } from "state/delegate";
import { streamsCacheSelectors } from "state/selectors";

interface OtherStreamData {
  accountId: string;
  streamId: string;
}

export const useModeratedOtherStreamsData = <T extends OtherStreamData>(
  otherStreamsData: T[]
) => {
  const { moderationMap, streamMaxModerationLevelFullAccess } = useSelector(
    (state: RootState) => {
      const streamMaxModerationLevelFullAccess =
        getStreamMaxModerationLevelFullAccess(state);

      const streamModerationLevelWhenUndefinedInResponse =
        getStreamModerationLevelWhenUndefinedInResponse(state);

      const moderationMap = otherStreamsData.reduce(
        (map: { [streamId: string]: Nullable<number> }, otherStreamData) => {
          const stream = streamsCacheSelectors.getStreamById(
            state,
            otherStreamData.streamId
          );

          if (stream) {
            map[otherStreamData.streamId] =
              typeof stream.moderationLevel === "number"
                ? stream.moderationLevel
                : streamModerationLevelWhenUndefinedInResponse;
          } else {
            map[otherStreamData.streamId] = null;
          }

          return map;
        },
        {}
      );

      return {
        moderationMap,
        streamMaxModerationLevelFullAccess,
      };
    }
  );

  const moderatedOtherStreamsData = useMemo(
    () =>
      otherStreamsData.filter((otherStreamData) => {
        const moderationLevel = moderationMap[otherStreamData.streamId];

        if (moderationLevel === null) {
          return false;
        }

        return moderationLevel <= streamMaxModerationLevelFullAccess;
      }),
    [moderationMap, otherStreamsData, streamMaxModerationLevelFullAccess]
  );

  return moderatedOtherStreamsData;
};
