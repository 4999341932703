/* eslint-disable no-restricted-imports */
import {
  SEND_MESSAGE_TO_SESSION_BEGIN,
  SEND_MESSAGE_TO_SESSION_END,
  VIEWER_SESSION_BOUGHT_TICKET,
  VIEWER_SESSION_CLEAR_QUEUED_GIFT_EVENT,
  VIEWER_SESSION_CLEAR_TOP_GIFTER_MESSAGE_EVENT,
  VIEWER_SESSION_CLOSE_PIP_PLAYER,
  VIEWER_SESSION_FORCE_EVENTS_MAX_LENGTH,
  VIEWER_SESSION_GIFT_EVENT_SOUND_PROCESSED,
  VIEWER_SESSION_INITIALIZATION_END,
  VIEWER_SESSION_LEFT,
  VIEWER_SESSION_LIVE_CHAT_SET_IS_TRANSLATED,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_BEGIN,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_END,
  VIEWER_SESSION_LIVE_CHAT_TRANSLATION_ERROR,
  VIEWER_SESSION_NOTIFICATIONS,
  VIEWER_SESSION_PREMIUM_MB_START_WATCH,
  VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  VIEWER_SESSION_PULL_EVENTS_LOADED_WINDOW,
  VIEWER_SESSION_RESET,
  VIEWER_SESSION_RESET_COMPETITION,
  VIEWER_SESSION_SET_CLEAR_VIEW,
  VIEWER_SESSION_SET_DIRTY_FLAGS,
  VIEWER_SESSION_SET_TOP_GIFTERS_REQUEST_LIMIT,
  VIEWER_SESSION_TOP_GIFTERS_BEGIN_FETCH,
  VIEWER_SESSION_TOP_GIFTERS_END_FETCH,
  VIEWER_SESSION_UPDATE,
  VIEWER_SESSION_WILL_BUY_TICKET,
} from "state/actionTypes";

export const resetViewerSession = (
  streamId,
  keepEventTypes = [],
  multibroadcastSwitch
) => ({
  type: VIEWER_SESSION_RESET,
  payload: streamId,
  meta: { keepEventTypes, multibroadcastSwitch },
});

export const endSessionInitialization = (streamId, result, source) => ({
  type: VIEWER_SESSION_INITIALIZATION_END,
  payload: result,
  meta: { streamId, source },
});

export const leftViewerSession = (streamId) => ({
  type: VIEWER_SESSION_LEFT,
  meta: { streamId },
});

export const willSendMessage = ({
  message,
  streamId,
  currentUserId,
  requestId,
}) => ({
  type: SEND_MESSAGE_TO_SESSION_BEGIN,
  payload: message,
  meta: { streamId, currentUserId, requestId },
});

export const didSendMessage = ({
  streamId,
  currentUserId,
  requestId,
  paywall,
  censored,
  failedToSend,
}) => ({
  type: SEND_MESSAGE_TO_SESSION_END,
  meta: { paywall, streamId, currentUserId, requestId, censored, failedToSend },
});

export const failedToSendMessage = ({
  streamId,
  currentUserId,
  requestId,
  paywall,
  error,
}) => ({
  type: SEND_MESSAGE_TO_SESSION_END,
  meta: { streamId, currentUserId, requestId, paywall },
  payload: error,
  error: true,
});

export const updatedViewerSession = ({
  streamId,
  currentUserId,
  broadcasterId,
  ...data
}) => ({
  type: VIEWER_SESSION_UPDATE,
  payload: { ...data },
  meta: { streamId, broadcasterId, currentUserId },
});

export const failedToUpdateViewerSession = ({
  streamId,
  broadcasterId,
  error,
}) => ({
  type: VIEWER_SESSION_UPDATE,
  payload: error,
  error: true,
  meta: { streamId, broadcasterId },
});

export const updatePremiumMBStartWatch = ({ streamId, streamInfo }) => ({
  type: VIEWER_SESSION_PREMIUM_MB_START_WATCH,
  payload: { streamId, streamInfo },
});

export const setDirtyFlags = ({ streamId, ...rest }) => ({
  type: VIEWER_SESSION_SET_DIRTY_FLAGS,
  meta: { streamId },
  payload: rest,
});

export const willBuyTicket = () => ({
  type: VIEWER_SESSION_WILL_BUY_TICKET,
});

export const boughtTicket = ({ streamId, balance }) => ({
  type: VIEWER_SESSION_BOUGHT_TICKET,
  payload: { balance, streamId },
});

export const failedToBuyTicket = (error) => ({
  type: VIEWER_SESSION_BOUGHT_TICKET,
  error: true,
  payload: error,
});

export const updatedNotifications = ({ streamId, payload }) => ({
  type: VIEWER_SESSION_NOTIFICATIONS,
  payload,
  meta: { streamId },
});

export const clearQueuedGiftEvent = (giftEvent) => ({
  type: VIEWER_SESSION_CLEAR_QUEUED_GIFT_EVENT,
  payload: giftEvent,
});

export const clearTopGifterMessage = () => ({
  type: VIEWER_SESSION_CLEAR_TOP_GIFTER_MESSAGE_EVENT,
});

export const trimEvents = (maxSize) => ({
  type: VIEWER_SESSION_FORCE_EVENTS_MAX_LENGTH,
  payload: { maxSize },
});

export const setTopGiftersRequestLimit = (limit) => ({
  type: VIEWER_SESSION_SET_TOP_GIFTERS_REQUEST_LIMIT,
  payload: limit,
});

export const willLoadTopGifters = ({ streamId }) => ({
  type: VIEWER_SESSION_TOP_GIFTERS_BEGIN_FETCH,
  meta: { streamId },
});

export const loadedTopGifters = ({ streamId, ...data }) => ({
  type: VIEWER_SESSION_TOP_GIFTERS_END_FETCH,
  payload: data,
  meta: { streamId },
});

export const failedToLoadTopGifters = ({ streamId, error }) => ({
  type: VIEWER_SESSION_TOP_GIFTERS_END_FETCH,
  error: true,
  payload: error,
  meta: { streamId },
});

export const loadedRichWindow = ({ streamId, payload }) => ({
  type: VIEWER_SESSION_PULL_EVENTS_LOADED_WINDOW,
  meta: { streamId },
  payload,
});

export const failedToLoadRichWindow = ({ streamId, error }) => ({
  type: VIEWER_SESSION_PULL_EVENTS_LOADED_WINDOW,
  error: true,
  payload: error,
  meta: { streamId },
});

export const loadedRichFragment = ({ streamId, currentUserId, payload }) => ({
  type: VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  payload,
  meta: { streamId, currentUserId },
});

export const failedToLoadRichFragment = ({ streamId, error, version }) => ({
  type: VIEWER_SESSION_PULL_EVENTS_LOADED_FRAGMENT,
  error: true,
  payload: error,
  meta: { streamId, version },
});

export const resetCompetition = () => ({
  type: VIEWER_SESSION_RESET_COMPETITION,
});

export const closePipPlayer = () => ({
  type: VIEWER_SESSION_CLOSE_PIP_PLAYER,
});

export const giftEventSoundProcessed = (eventId) => ({
  type: VIEWER_SESSION_GIFT_EVENT_SOUND_PROCESSED,
  payload: eventId,
});

export const liveChatTranslationBegin = ({ event, locale }) => ({
  type: VIEWER_SESSION_LIVE_CHAT_TRANSLATION_BEGIN,
  payload: { event, locale },
});

export const liveChatTranslationEnd = ({
  event,
  language,
  locale,
  translationResponse = {},
}) => ({
  type: VIEWER_SESSION_LIVE_CHAT_TRANSLATION_END,
  payload: { event, language, locale, ...translationResponse },
});

export const liveChatTranslationError = ({ event, error }) => ({
  type: VIEWER_SESSION_LIVE_CHAT_TRANSLATION_ERROR,
  payload: { event, error },
});

export const liveChatSetIsTranslated = ({ event, isTranslated }) => ({
  type: VIEWER_SESSION_LIVE_CHAT_SET_IS_TRANSLATED,
  payload: { event, isTranslated },
});

export const setClearView = (value) => ({
  type: VIEWER_SESSION_SET_CLEAR_VIEW,
  payload: value,
});
