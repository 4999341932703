import messages from "chat/components/common/DeleteAllButton/messages";
import { sharedMessages } from "chat/imports/utils";
import {
  getIsChatDeleteMessageEnabled,
  getIsChatEditMessageEnabled,
} from "chat/soc/chatSoc";
import { MessageMenuOptionType } from "chat/types";
import { ReactComponent as DeleteIcon } from "img/chat/delete_32.svg";
import { ReactComponent as EditIcon } from "img/chat/edit_32.svg";
import { ReactComponent as TranslateIcon } from "img/chat/translate_32.svg";

export const MESSAGE_POPOVER_OPTIONS: { [key: string]: MessageMenuOptionType } =
  {
    translate: {
      id: "translate",
      message: messages.translate,
      icon: TranslateIcon,
      visibilityFn: getIsChatDeleteMessageEnabled,
    },
    delete: {
      id: "delete",
      message: messages.delete,
      icon: DeleteIcon,
      visibilityFn: getIsChatDeleteMessageEnabled,
    },
    edit: {
      id: "edit",
      message: sharedMessages.edit,
      icon: EditIcon,
      visibilityFn: getIsChatEditMessageEnabled,
    },
  };
