import { isSameDay } from "date-fns";
import { ignoredMessagesTypes } from "chat/constants";
import { TANGO_ACCOUNT_ID } from "chat/imports/constants";
import { getChatIdForLoadLandingsFromShouts } from "chat/imports/environment";
import {
  generateAsyncSelectors,
  typedDelegateSelectors,
} from "chat/imports/state";
import { getParsedExternalMessagePayload, uniq } from "chat/imports/utils";
import { ChatState, StoredMessage } from "chat/state/reducer";

const { data, meta } = generateAsyncSelectors<ChatState>();

export const localChatSelectors = {
  data,
  meta,
  getCurrentConversationId: (state: ChatState) =>
    data(state).currentConversationId,
  getConversations: (state: ChatState) => {
    const { conversations } = data(state);

    return Object.values(conversations).sort((a, b) => {
      const aTs = a.last_message_ts || 0;
      const bTs = b.last_message_ts || 0;
      if (aTs === bTs) {
        if (a.conversation_id === TANGO_ACCOUNT_ID) {
          return -1;
        }
        if (b.conversation_id === TANGO_ACCOUNT_ID) {
          return 1;
        }

        return a.conversation_id.localeCompare(b.conversation_id);
      }

      return bTs - aTs;
    });
  },
  getConversation: (state: ChatState, conversationId: string) =>
    data(state).conversations[conversationId],
  getConversationLoadingFailed: (state: ChatState, conversationId?: string) => {
    if (conversationId) {
      return (
        data(state).conversations[conversationId]?.isLoadingFailed || false
      );
    }

    return false;
  },
  getMessagesByConversationId: (state: ChatState, conversationId: string) =>
    data(state).messages[conversationId],
  getConversationMessagesByDates: (
    state: ChatState,
    conversationId: string
  ) => {
    const preparedData = (data(state).messages[conversationId] || []).reduce(
      (
        acc: {
          accountIds: string[];
          dates: { date: number; messages: StoredMessage[] }[];
          lastMessageTs: number;
          latestMessageFrom: string | undefined;
          messagesIds: string[];
        },
        next
      ) => {
        if (ignoredMessagesTypes.includes(next.type)) {
          return acc;
        }
        acc.lastMessageTs = next.id.ts;
        if (acc.messagesIds.length === 0) {
          acc.latestMessageFrom = next.from;
        }
        acc.messagesIds.push(`${next.id.id}`);

        if (next.from) {
          acc.accountIds.push(next.from);
        }

        if (acc.dates.length === 0) {
          acc.dates.push({
            date: next.id.ts,
            messages: [next],
          });

          return acc;
        }

        const isSameDayTs = isSameDay(
          acc.dates[acc.dates.length - 1].date,
          next.id.ts
        );

        if (isSameDayTs) {
          acc.dates[acc.dates.length - 1].messages.push(next);
        } else {
          acc.dates.push({
            date: next.id.ts,
            messages: [next],
          });
        }

        return acc;
      },
      {
        dates: [],
        messagesIds: [],
        lastMessageTs: 0,
        accountIds: [],
        latestMessageFrom: undefined,
      }
    );
    preparedData.accountIds = uniq(preparedData.accountIds);

    return preparedData;
  },
  getConversationLatestMessage: (state: ChatState, conversationId: string) =>
    data(state).messages[conversationId]?.find(
      (message) => !ignoredMessagesTypes.includes(message.type)
    ),
  canLoadMore: (state: ChatState) => data(state).canLoadMore,
  getTotalUnreadCount: (state: ChatState) => data(state).totalUnreadCount,
  getEditingMessageInProgress: (state: ChatState) =>
    data(state).editingMessageInProgress,
  getLpIds: (state: ChatState) => {
    const messages = data(state).messages[getChatIdForLoadLandingsFromShouts()];
    if (!messages) {
      return null;
    }

    return messages.reduce((acc: string[], message) => {
      if (!message.sdk_message_payload?.payload) {
        return acc;
      }

      const parsedPayload = getParsedExternalMessagePayload(
        message.sdk_message_payload.payload
      );

      if (!parsedPayload) {
        return acc;
      }

      const match = parsedPayload.actionInfo?.[0]?.actionUrl.match(
        /\/deeplink\/q\?additional_action=(.*?)(&|$)/
      );

      if (match && match[1] && !acc.includes(match[1])) {
        acc.push(match[1]);
      }

      return acc;
    }, []);
  },
};

export default typedDelegateSelectors(localChatSelectors, "chat");
