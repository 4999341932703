import { createAsyncThunk } from "@reduxjs/toolkit";
import sortBy from "lodash.sortby";
import { fetchMarketingPersonalOffers } from "src/features/visitorCashier/api/visitorCashier";
import { isApiError } from "src/features/visitorCashier/imports/api";
import {
  PersonalOffersConfig,
  RootState,
} from "src/features/visitorCashier/imports/types";

const SORT_OFFERS_BY = "priority";

export const loadVisitorMarketingPersonalOffers = createAsyncThunk<
  PersonalOffersConfig,
  void,
  { rejectValue: string; state: RootState }
>("lwc/visitorMarketingCashier/fetch", async (_, api) => {
  try {
    const response = await fetchMarketingPersonalOffers(true);

    return {
      ...response,
      pricePoints: sortBy(response.pricePoints, SORT_OFFERS_BY),
    };
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});

export const loadMarketingPersonalOffers = createAsyncThunk<
  PersonalOffersConfig,
  void,
  { rejectValue: string; state: RootState }
>("lwc/marketingCashier/fetch", async (_, api) => {
  try {
    const response = await fetchMarketingPersonalOffers(false);

    return {
      ...response,
      pricePoints: sortBy(response.pricePoints, SORT_OFFERS_BY),
    };
  } catch (e) {
    const error = isApiError(e) ? e.statusText : (e as Error).message;

    return api.rejectWithValue(error);
  }
});
