import { batch } from "react-redux";
import emptyFunction from "fbjs/lib/emptyFunction";
import { match } from "path-to-regexp";
import AnalyticsUtils from "@analytics/AnalyticsUtils";
import { emitEvent } from "@analytics/emit";
import {
  BI_LANDING_FIELD,
  EventFields,
  EventNames,
  PURCHASE_ORIGIN,
  PURCHASE_SOURCE,
  RegistrationSource,
  UI_COMPONENT,
} from "@analytics/enums";
import {
  GIFT_SOURCE_HAPPY_MOMENTS,
  GIFT_SOURCE_POST,
  GIFT_SOURCE_PRIVATE_TICKET,
  GIFT_SOURCE_PROFILE,
  GIFT_SOURCE_STREAM,
} from "@analytics/gifting/giftSource";
import {
  GIFTING_RESULT_INSUFFICIENT_BALANCE_LOCAL,
  GIFTING_RESULT_SUCCESS,
  mapErrorToGiftingResult,
} from "@analytics/gifting/giftingResult";
import selectBaseGiftingParams from "@analytics/gifting/selectBaseGiftingParams";
import { sendGiftToPost, sendGiftToProfile, sendGiftToStream } from "api/gifts";
import { sendFreeLike } from "api/happyMoments";
import { addGiftToPlay } from "chat/giftAnimation/export/state";
import { validateMappingToGiftRecipientType } from "enumAddons/giftRecipientType";
import { CASHIER, REFILL } from "enums/cashier";
import {
  CHAT,
  HAPPY_MOMENTS,
  POST,
  PROFILE,
  STREAM,
  UNSET,
} from "enums/giftRecipientType";
import { FROM_FLOW } from "enums/modalDismissReason";
import { NEW_SIGN_UP_PROMOTION_TYPE_GIFT_OR_LIKE } from "enums/newSignUpPromotionType";
import { OneClickEndReason, OneClickEndResult } from "enums/oneClickOffers";
import { BOTTOM_SCREEN_HIDE_ANIMATION_DURATION } from "src/constants";
import { analyticsSendGift } from "src/core/analytics/utils/analyticsSendGift";
import {
  BottomScreenType,
  LoginModalTitleType,
  LoginPromotionType,
  ModalType,
  TCNNAction,
  ToastType,
} from "src/enums";
import { openLoginView } from "src/features/signin/exports/state/flows";
import { AsyncActionQueue } from "src/state/middleware/asyncActionQueue";
import { addToast, removeToast } from "src/state/tree/toast";
import { linkToStreamMatch } from "src/ui/navigation/links";
import { currentTimeMillis } from "src/utils/dateUtils";
import getOneClickAnalyticsParams from "src/utils/getOneClickAnalyticsParams";
import getOneClickOfferType from "src/utils/getOneClickOfferType";
import { getOneClickV2AnalyticsData } from "src/utils/getOneClickV2AnalyticsData";
import {
  getHappyMomentsGuestFreeLikesEnabled,
  getIsCashierDesignV2DesktopEnabled,
  getIsCashierDesignV2Enabled,
  getIsPaymentFlowV2Enabled,
  getIsRefillV2DesktopEnabled,
  getIsRefillV2MobileEnabled,
  getOneClickPurchaseEnabled,
  getOneClickV2PurchaseEnabled,
  getPersonalOffersCashierRefillEnabled,
  getWebDeclineSelfGiftingEnabled,
} from "state/abTests";
import {
  hideBottomScreen,
  openGiftsDrawerInStreamBottomScreen,
  setAnimation,
} from "state/actionCreators/bottomScreen";
import { processSendGiftFail, sentGift } from "state/actionCreators/gift";
import {
  dismissModalWithType,
  openGiftsDrawerModal,
} from "state/actionCreators/modal";
import { genericNetworkError } from "state/actionCreators/networkError";
import { openBuyCoins } from "state/actionCreators/openBuyCoins";
import { removeSelectedTcnnMessage } from "state/actionCreators/tcnn";
import findClosestOfferToBuyGift from "state/flows/utils/findClosestOffer";
import { loadClosestPersonalOffer } from "state/flows/utils/loadClosestPersonalOffer";
import {
  bottomScreenSelectors,
  giftingRequestsSelectors,
  giftsCacheSelectors,
  loginSelectors,
  modalSelectors,
  navigationSelectors,
  settingsSelectors,
  shopSelectors,
  tcnnSelectors,
  userSelectors,
  viewerSessionSelectors,
} from "state/selectors";
import { actionCreators } from "state/tree/giftsCache";
import { actionCreators as settingsActionCreators } from "state/tree/settings";
import { FREE_LIKE_ID } from "state/tree/stories";
import sharedMessages from "ui/common/intl/sharedMessages";
import { GIFT_SENT } from "../actionTypes";
import retryAfterCaptcha from "./utils/retryAfterCaptcha";

const giftRecipientToRegistrationSource = {
  [UNSET]: RegistrationSource.SEND_GIFT,
  [PROFILE]: RegistrationSource.ANOTHER_PROFILE_SEND_GIFT,
  [POST]: RegistrationSource.ANOTHER_PROFILE_SEND_GIFT,
  [STREAM]: RegistrationSource.SEND_GIFT,
  [HAPPY_MOMENTS]: RegistrationSource.SEND_GIFT_HAPPY_MOMENTS,
  [CHAT]: RegistrationSource.ANOTHER_PROFILE_CHAT,
};
validateMappingToGiftRecipientType(giftRecipientToRegistrationSource);

const giftSourceToPurchaseSource = {
  [GIFT_SOURCE_STREAM]: PURCHASE_SOURCE.STREAM,
  [GIFT_SOURCE_POST]: PURCHASE_SOURCE.OFFLINE_GIFTING_POST,
  [GIFT_SOURCE_PROFILE]: PURCHASE_SOURCE.OFFLINE_GIFTING_PROFILE,
  [GIFT_SOURCE_HAPPY_MOMENTS]: PURCHASE_SOURCE.HAPPY_MOMENTS,
  [GIFT_SOURCE_PRIVATE_TICKET]: PURCHASE_SOURCE.PAID_ENTRY,
};

export const sendGiftQueue = new AsyncActionQueue();

export const sendGiftSchedule = sendGiftQueue.createWithPresetOptions({
  done: (action) => action.type === GIFT_SENT,
});

const notifySelfGiftingToast = {
  id: "notifySelfGifting",
  type: ToastType.REGULAR,
  title: sharedMessages.notifySelfGifting,
};

const sendGift =
  ({
    giftId,
    confirmed = false,
    timestamp = currentTimeMillis(),
    type = "",
    isMobile,
    afterSuccessfulPayment = false,
    errorCallback = emptyFunction,
    giftSelectionOrigin,
    realTimeGiftFromType = "",
    offerType,
    isOneClickPurchase = false,
  }) =>
  async (dispatch, getState) => {
    if (!giftId) {
      sendGiftQueue.resolveCurrentTask();

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject("Gift id should be provided!");
    }

    const state = getState();
    const recipientType = giftingRequestsSelectors.getRecipientType(state);

    const giftingLocation = giftsCacheSelectors.getOneClickFlowLocation(state);

    const recipientAccountId =
      giftingRequestsSelectors.getRecipientAccountId(state);
    const secondaryRecipientId =
      giftingRequestsSelectors.getSecondaryRecipientId(state);
    const selectedTcnnMessage = tcnnSelectors.getSelectedTcnnMessage(state);

    let gift = giftsCacheSelectors.getGiftById(state, giftId);
    if (giftId === FREE_LIKE_ID) {
      gift = { priceInCredit: 0 };
    }

    if (!gift) {
      sendGiftQueue.resolveCurrentTask();

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(`Gift with id ${giftId} not found`);
    }

    if (!recipientType || !recipientAccountId) {
      sendGiftQueue.resolveCurrentTask();

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject("Recipient type and account id must be set");
    }
    if (recipientType !== PROFILE && !secondaryRecipientId) {
      sendGiftQueue.resolveCurrentTask();

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        `Secondary recipient type must be set for recipientType ${recipientType}`
      );
    }

    if (
      !loginSelectors.isLoggedIn(state) &&
      !(
        recipientType === HAPPY_MOMENTS &&
        getHappyMomentsGuestFreeLikesEnabled(state) &&
        giftId === FREE_LIKE_ID
      )
    ) {
      sendGiftQueue.clear();

      dispatch(
        openLoginView({
          promotionType: LoginPromotionType.GIFT,
          registrationSource: giftRecipientToRegistrationSource[recipientType],
          accountId: recipientAccountId,
          newSignUpPromotionType: NEW_SIGN_UP_PROMOTION_TYPE_GIFT_OR_LIKE,
          title: LoginModalTitleType.DO_MORE_WITH_TANGO,
        })
      );

      return Promise.resolve();
    }

    const currentUserId = userSelectors.getMyAccountId(state);
    const isDeclineSelfGiftingEnabled = getWebDeclineSelfGiftingEnabled(state);

    if (isDeclineSelfGiftingEnabled) {
      if (recipientAccountId === currentUserId) {
        batch(() => {
          dispatch(removeToast(notifySelfGiftingToast.id));
          dispatch(addToast(notifySelfGiftingToast));
        });

        sendGiftQueue.resolveCurrentTask();

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject("Gift cannot be sent to your own account.");
      }
    }

    const analyticParams = selectBaseGiftingParams(state, giftId);
    const emitEventWithResult = (result, isOneClickPurchase) => {
      const tcnnTrackingId = [
        TCNNAction.SEND_ANY_GIFT,
        TCNNAction.SEND_THIS_GIFT,
      ].includes(selectedTcnnMessage.action)
        ? selectedTcnnMessage.tracking_id
        : undefined;
      emitEvent(EventNames.SEND_GIFT, {
        ...analyticParams,
        [EventFields.RESULT]: result,
        [EventFields.A_TCNN_TRACKING_NUMBER]: tcnnTrackingId,
        [EventFields.ONE_CLICK_PURCHASE]: isOneClickPurchase ? 1 : 0,
      });
      dispatch(removeSelectedTcnnMessage());
    };

    const coinsBalance = userSelectors.getCoinsBalance(state);
    const pointsBalance = userSelectors.getPointsBalance(state);
    const streamerId = viewerSessionSelectors.getBroadcasterId(state);

    const isBuyWithCoins =
      giftsCacheSelectors.isBuyGiftWithCoins(state) || !gift.withdrawInPoint;
    const giftsBalance = userSelectors.getGiftsBalance(state);
    const giftBalance = giftsBalance.find(
      (balance) => balance.gift === giftId
    )?.amount;
    const bonusPercentage = viewerSessionSelectors.getBonusPercentage(state);

    const userBalance = isBuyWithCoins ? coinsBalance : pointsBalance;
    const giftPrice = isBuyWithCoins
      ? gift.priceInCredit
      : gift.withdrawInPoint;

    AnalyticsUtils.updateInteractionId();

    const purchaseSource =
      giftSourceToPurchaseSource[analyticParams.GiftSource];

    if (userBalance < giftPrice && !giftBalance) {
      sendGiftQueue.clear();

      let closestOfferToBuyGift = null;
      let offer;
      let isOneClickPurchase = false;

      const oneClickPurchaseAnalyticsArgs = {
        [EventFields.SOURCE]: giftSelectionOrigin,
        [EventFields.LOGGED_IN_ACCOUNT_ID]: currentUserId,
        [EventFields.GIFT_ID]: giftId,
        [EventFields.PLACE]: analyticParams.GiftSource,
      };

      const isPersonalOffersCashierRefillEnabled =
        getPersonalOffersCashierRefillEnabled(state);

      const isOneClickPurchaseEnabled = getOneClickPurchaseEnabled(state);
      const isOneClickV2PurchaseEnabled = getOneClickV2PurchaseEnabled(state);

      const isOneClickFlowEnabled =
        isPersonalOffersCashierRefillEnabled &&
        (isOneClickPurchaseEnabled || isOneClickV2PurchaseEnabled);

      const giftOfferType = getOneClickOfferType({
        isOneClickV2PurchaseEnabled,
        offerType,
        giftingLocation,
      });

      let oneClickV2PurchaseAnalyticsArgs = {
        [EventFields.GIFT_ID]: giftId,
        [EventFields.ONE_CLICK_TYPE]: giftOfferType,
        ...getOneClickV2AnalyticsData(
          giftOfferType,
          recipientAccountId,
          secondaryRecipientId
        ),
      };

      if (isOneClickFlowEnabled) {
        offer = await loadClosestPersonalOffer({
          giftPrice,
          userBalance,
          state,
          analyticsParams: oneClickV2PurchaseAnalyticsArgs,
          offerType: giftOfferType,
          source: giftOfferType,
        });

        if (offer) {
          if (Array.isArray(offer.pricePoints) && offer.pricePoints[0]) {
            closestOfferToBuyGift = offer.pricePoints[0];
            isOneClickPurchase = true;

            oneClickV2PurchaseAnalyticsArgs = {
              ...oneClickV2PurchaseAnalyticsArgs,
              [BI_LANDING_FIELD.PRICE_POINT_ID]: closestOfferToBuyGift.id,
              [BI_LANDING_FIELD.MARKET_OFFER_ID]:
                closestOfferToBuyGift.offers[0].marketOfferId,
            };
          }

          oneClickPurchaseAnalyticsArgs[BI_LANDING_FIELD.TRIGGER_ID] =
            offer.triggerId;

          oneClickV2PurchaseAnalyticsArgs = {
            ...oneClickV2PurchaseAnalyticsArgs,
            [BI_LANDING_FIELD.TRIGGER_ID]: offer.triggerId,
            [BI_LANDING_FIELD.SERVER_OFFER_ID]: offer.personalOfferId,
          };
        }
      } else {
        const offers = shopSelectors.getListOfPurchasableItems(state);

        closestOfferToBuyGift = findClosestOfferToBuyGift({
          coinsBalance,
          gift,
          offers,
        });
      }

      let oneClickV2ResultAnalyticsArgs;

      const onSuccess = () => {
        dispatch(
          actionCreators.setGiftAfterBuy({ id: giftId, isOneClickPurchase })
        );

        emitEvent(
          EventNames.GIFT_PURCHASE_SUCCESSFUL,
          oneClickPurchaseAnalyticsArgs
        );

        if (isOneClickFlowEnabled) {
          oneClickV2ResultAnalyticsArgs = {
            [EventFields.RESULT]: OneClickEndResult.SUCCESSFUL,
          };
        }
      };

      const onError = () => {
        emitEvent(
          EventNames.GIFT_PURCHASE_FAILED,
          oneClickPurchaseAnalyticsArgs
        );

        if (isOneClickFlowEnabled) {
          oneClickV2ResultAnalyticsArgs = {
            [EventFields.RESULT]: OneClickEndResult.UNSUCCESSFUL,
            [EventFields.REASON]: OneClickEndReason.PURCHASE_FAILED,
          };
        }
      };

      const onDismiss = () => {
        if (isOneClickFlowEnabled) {
          oneClickV2ResultAnalyticsArgs ??= {
            [EventFields.RESULT]: OneClickEndResult.UNSUCCESSFUL,
            [EventFields.REASON]: OneClickEndReason.PURCHASE_REFUSED,
          };

          emitEvent(EventNames.ONE_CLICK_END, {
            ...oneClickV2ResultAnalyticsArgs,
            ...oneClickV2PurchaseAnalyticsArgs,
          });
        }
      };

      // it's made for test
      if (afterSuccessfulPayment) {
        onSuccess();
      }

      let purchaseOrigin;
      if (isOneClickPurchase) {
        purchaseOrigin = giftOfferType ?? PURCHASE_ORIGIN.WEB_ONE_CLICK;
      }

      const isRefillV2MobileEnabled = getIsRefillV2MobileEnabled(state);
      const isRefillV2DesktopEnabled = getIsRefillV2DesktopEnabled(state);
      const isCashierV2MobileEnabled = getIsCashierDesignV2Enabled(state);
      const isCashierV2DesktopEnabled =
        getIsCashierDesignV2DesktopEnabled(state);
      const isPaymentFlowV2Enabled = getIsPaymentFlowV2Enabled(state);

      const onRefillV2BottomScreenClose = () => {
        dispatch(setAnimation(true));

        setTimeout(() => {
          dispatch(hideBottomScreen());
          dispatch(openGiftsDrawerInStreamBottomScreen());
        }, BOTTOM_SCREEN_HIDE_ANIMATION_DURATION);
      };

      const currentRoute = navigationSelectors.getCurrentRoute(state);
      const isStream = match(linkToStreamMatch, { end: false })(currentRoute);

      dispatch(
        openBuyCoins({
          analyticsParams: getOneClickAnalyticsParams(offer),
          offer: closestOfferToBuyGift,
          purchaseSource,
          purchaseOrigin,
          isMobile,
          streamerId,
          viewType: isStream ? REFILL : CASHIER,
          uiComponent: isStream ? UI_COMPONENT.REFILL : UI_COMPONENT.CASHIER,
          previousUiComponent: UI_COMPONENT.SEND_GIFT,
          shouldDismissOnBack: [
            PURCHASE_SOURCE.OFFLINE_GIFTING_POST,
            PURCHASE_SOURCE.OFFLINE_GIFTING_PROFILE,
            PURCHASE_SOURCE.STREAM,
          ].includes(purchaseSource),
          onSuccess,
          onError,
          onDismiss,
          onRefillV2BottomScreenClose,
          isRefillV2MobileEnabled,
          isCashierV2MobileEnabled,
          isCashierV2DesktopEnabled,
          isPaymentFlowV2Enabled,
          ...(isRefillV2DesktopEnabled &&
            !closestOfferToBuyGift && {
              modalType: isStream
                ? ModalType.REFILL_MODAL
                : ModalType.CASHIER_MODAL,
            }),
        })
      );

      analyticsSendGift(giftPrice, analyticParams, isOneClickPurchase);

      emitEventWithResult(
        GIFTING_RESULT_INSUFFICIENT_BALANCE_LOCAL,
        isOneClickPurchase
      );
      emitEvent(EventNames.GIFT_INSUFFICIENT_BALANCE, {
        [EventFields.GIFT_ID]: giftId,
        [EventFields.SOURCE]: giftSelectionOrigin,
        [EventFields.PLACE]: analyticParams.GiftSource,
        [EventFields.ONE_CLICK_GIFTING]:
          settingsSelectors.isOneClickGiftingEnabled(state),
        [EventFields.CURRENT_BALANCE]: coinsBalance,
        [EventFields.LOGGED_IN_ACCOUNT_ID]: currentUserId,
        [EventFields.RECIPIENT_ACCOUNT_ID]: recipientAccountId,
      });

      dispatch(removeSelectedTcnnMessage());

      return Promise.resolve();
    }

    if (
      !settingsSelectors.isOneClickGiftingEnabled(state) &&
      !confirmed &&
      giftId !== FREE_LIKE_ID
    ) {
      const bottomScreenType = bottomScreenSelectors.screenType(state);
      const topModalType = modalSelectors.topModalType(state);
      const isGiftsDrawerVisible =
        settingsSelectors.isGiftsDrawerVisible(state);

      if (
        isMobile &&
        recipientType === STREAM &&
        bottomScreenType !== BottomScreenType.GIFTS_DRAWER_BOTTOM_SCREEN
      ) {
        dispatch(
          openGiftsDrawerInStreamBottomScreen({
            screenData: { canBackToGifts: false },
          })
        );
      } else if (
        isMobile &&
        recipientType === CHAT &&
        bottomScreenType !== BottomScreenType.GIFTS_DRAWER_BOTTOM_SCREEN
      ) {
        dispatch(
          openGiftsDrawerInStreamBottomScreen({
            screenData: { canBackToGifts: false },
          })
        );
      } else if (
        !isMobile &&
        recipientType === CHAT &&
        topModalType !== ModalType.GIFTS_DRAWER
      ) {
        dispatch(openGiftsDrawerModal({ canBackToGifts: false }));
      } else if (
        !isMobile &&
        recipientType === PROFILE &&
        topModalType !== ModalType.GIFTS_DRAWER
      ) {
        dispatch(openGiftsDrawerModal({ canBackToGifts: false }));
      } else if (
        !isMobile &&
        recipientType === STREAM &&
        !isGiftsDrawerVisible
      ) {
        dispatch(
          settingsActionCreators.toggleGiftsDrawerVisibility({
            canBackToGifts: false,
          })
        );
      }

      return Promise.resolve(
        dispatch(
          actionCreators.setGiftIdToConfirm({ giftId, realTimeGiftFromType })
        )
      );
    }

    const withPoint = !isBuyWithCoins;
    const basePayload = {
      requestId: timestamp,
      giftId,
      priceInCredit: giftPrice,
      currentUserId,
      withPoint,
    };

    const wrapWithAnalytics = (promise) =>
      promise
        .then(() => GIFTING_RESULT_SUCCESS)
        .catch((error) => {
          sendGiftQueue.resolveCurrentTask();
          dispatch(genericNetworkError(error));
          retryAfterCaptcha(
            error,
            sendGift({
              giftId,
              confirmed,
              type,
              isMobile,
            })
          );

          return mapErrorToGiftingResult(error);
        })
        .then((result) => {
          analyticsSendGift(giftPrice, analyticParams, isOneClickPurchase);

          return emitEventWithResult(result, isOneClickPurchase);
        });

    switch (recipientType) {
      case POST: {
        return wrapWithAnalytics(
          sendGiftToPost({
            giftId,
            postId: secondaryRecipientId,
            authorId: recipientAccountId,
          }).then(({ finalCredit, additionInPoint }) => {
            batch(() => {
              dispatch(
                dismissModalWithType({
                  modalType: ModalType.GIFTS_DRAWER,
                  modalDismissReason: FROM_FLOW,
                })
              );

              dispatch(addGiftToPlay(giftId));

              dispatch(
                sentGift({
                  ...basePayload,
                  finalCredit,
                  additionInPoint,
                  userId: recipientAccountId,
                  postId: secondaryRecipientId,
                })
              );
            });
          })
        );
      }
      case STREAM: {
        return wrapWithAnalytics(
          sendGiftToStream({
            giftId,
            streamId: secondaryRecipientId,
            streamerId: recipientAccountId,
            withPoint,
          }).then(({ finalCredit, finalPoint, additionInPoint }) => {
            batch(() => {
              dispatch(
                sentGift({
                  ...basePayload,
                  finalCredit,
                  finalPoint,
                  additionInPoint,
                  realTimeGiftFromType,
                  userId: recipientAccountId,
                  streamId: secondaryRecipientId,
                  bonusPercentage,
                })
              );
              dispatch(hideBottomScreen());
            });
          })
        );
      }
      case PROFILE:
      case CHAT: {
        return wrapWithAnalytics(
          sendGiftToProfile({ giftId, accountId: recipientAccountId }).then(
            ({ finalCredit, additionInPoint }) => {
              batch(() => {
                dispatch(
                  dismissModalWithType({
                    modalType: ModalType.GIFTS_DRAWER,
                    modalDismissReason: FROM_FLOW,
                  })
                );

                dispatch(setAnimation(true));

                setTimeout(() => {
                  dispatch(hideBottomScreen());
                }, BOTTOM_SCREEN_HIDE_ANIMATION_DURATION);

                dispatch(addGiftToPlay(giftId));

                dispatch(
                  sentGift({
                    ...basePayload,
                    finalCredit,
                    additionInPoint,
                    userId: recipientAccountId,
                  })
                );
              });
            }
          )
        );
      }
      case HAPPY_MOMENTS: {
        dispatch(
          sentGift({
            ...basePayload,
            userId: recipientAccountId,
            momentId: secondaryRecipientId,
            type,
          })
        );

        return wrapWithAnalytics(
          sendFreeLike(secondaryRecipientId).catch(() => {
            dispatch(
              processSendGiftFail({
                ...basePayload,
                userId: recipientAccountId,
                momentId: secondaryRecipientId,
                type,
              })
            );
            errorCallback();
          })
        );
      }
    }
  };

export default sendGift;
