import React, { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Node } from "gl-react";
import { Surface } from "gl-react-dom";
import { GLViewDOM } from "gl-react-dom/GLViewDOM";
import { Video } from "src/features/giftAnimation/components/Video";
import { Breakpoints } from "src/features/giftAnimation/imports/enums";
import {
  Nullable,
  VoidCallback,
} from "src/features/giftAnimation/imports/types";
import { useBreakpointPrecise } from "src/features/giftAnimation/imports/ui";
import { isStandalone } from "src/features/giftAnimation/imports/utils";
import { SHADERS } from "src/features/giftAnimation/shaders/shader";
import styles from "./Shader.scss";

interface ShaderProps {
  isSoundEnabled: boolean;
  onComplete?: VoidCallback;
  videoUrl: string;
}

interface ExtendedSurface extends Surface {
  destroy?: VoidCallback;
  glView: GLViewDOM;
}

const DEVICE_SCALE: { [key in Breakpoints]: number } = {
  [Breakpoints.MOBILE]: 0.8,
  [Breakpoints.SMALL_MOBILE]: 0.8,
  [Breakpoints.DESKTOP]: 0.8,
  [Breakpoints.TABLET]: 0.6,
};

const PWA_SCALE = 0.9;
const WEB_GL_EXT = "WEBGL_lose_context";
const WEB_GL_ATTRIBUTES = {
  antialias: false,
  preserveDrawingBuffer: false,
  powerPreference: "low-power",
} as WebGLContextAttributes;

export const Shader: FC<ShaderProps> = ({
  videoUrl,
  isSoundEnabled,
  onComplete,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const surfaceRef = useRef<Nullable<ExtendedSurface>>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const breakpoint = useBreakpointPrecise();

  useLayoutEffect(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, []);

  useEffect(
    () => () => {
      if (surfaceRef.current) {
        const gl = surfaceRef.current.glView?.gl;
        if (gl) {
          const ext = gl.getExtension(WEB_GL_EXT);
          ext?.loseContext();
        }
        surfaceRef.current.destroy?.();
      }
    },
    []
  );

  const renderVideoNode = (redraw: (time: number) => void) => (
    <Video
      onFrame={redraw}
      isSoundEnabled={isSoundEnabled}
      onComplete={onComplete}
      videoUrl={videoUrl}
    />
  );

  return (
    <div ref={containerRef} className={styles.root}>
      {dimensions.width > 0 && dimensions.height > 0 && (
        <Surface
          ref={surfaceRef}
          width={dimensions.width}
          height={dimensions.height}
          webglContextAttributes={WEB_GL_ATTRIBUTES}
          pixelRatio={1}
        >
          <Node
            shader={SHADERS.SplitColor}
            uniforms={{
              video: renderVideoNode,
              scale: isStandalone ? PWA_SCALE : DEVICE_SCALE[breakpoint],
            }}
          />
        </Surface>
      )}
    </div>
  );
};
