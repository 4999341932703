import { UtmParams } from "@analytics/enums";
import { AuctionTab, Currency, DeviceType } from "src/enums";
import { LanguageByIsoCodeMapping, LanguageIsoCode } from "src/types/common";
import { StreamStatus } from "src/types/richFragment/Stream";

export const STREAM_CHAT_MAX_TEXT_LENGTH_DEFAULT = 160;
export const STREAM_MAX_EVENTS_TO_KEEP_DEFAULT = 200;
export const STREAM_TOP_GIFTERS_BUBBLE_MESSAGE_LIMIT = 3;
export const STREAM_TOP_GIFTERS_DEFAULT_LIMIT = 10;
export const STREAM_TOP_GIFTERS_IN_MODAL_LIMIT = 100;
export const STREAM_PULL_EVENTS_LAG_TO_SKIP = 3;
export const STREAM_PULL_EVENTS_DEFAULT_INTERVAL = 1000;
export const COLLECTIBLE_GIFTS_TTL_MS = 60000; // should not refresh collectible gifts of user more often than once per minute

export const MIN_AGE_TO_BROADCAST = 12;

export const NOTIFICATION_SHOW_DURATION_MILLIS = 5000;
export const MAX_NOTIFICATIONS = 3;
export const TOAST_SHOW_DURATION_MILLIS = 5000;
export const REDIRECTION_COUNTDOWN_INITIAL_SEC = 8;

export const DEFAULT_MOMENTS_POST_COUNT = 18;

export const ROOT_PATH = "/";
export const SELECTED_CARD = "selectedCard";
export const DARK_THEME = "darkMode";

export const TANGO_DOMAIN = "tango.me";

// native app links
export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.sgiggle.production";
export const APP_STORE_LINK =
  "https://itunes.apple.com/us/app/tango-text-voice-video/id372513032?mt=8";
export const CUSTOMER_SUPPORT_LINK = "https://help.tango.me/";
export const AFFILIATE_PROGRAM_LINK = "https://affiliate.tango.me/register";
export const CUSTOMER_SUPPORT_EMAIL = "support@tango.me";

// FireBase dynamic links
// https://console.firebase.google.com/u/0/project/tango-messenger-production/durablelinks
export const FB_LINK_START_BROADCAST = "https://links.tango.me/broadcast";

export const CLIENT_CAPABILITIES_ARRAY = [
  "device.linking",
  "tcalttext",
  "acme.v1",
  "tc21",
  "tc.stranger",
  "groupmessage",
  "ndigit.sms.validation",
  "social.v6",
  "live.social.notification:1",
  "live.social.private",
  "live.social.private:2",
  "live.social.pullevents",
  "externalmessage",
];

export const CLIENT_CAPABILITIES = CLIENT_CAPABILITIES_ARRAY.join(",");

export const TANGO_ACCOUNT_ID = "mC5mPUPZh1ZsQP2zhN8s-g"; // this is encrypted 0, some system messages and shouts are sent there

export const PROFILE_MAX_NAME_COMPONENT_LENGTH = 64;
export const PROFILE_MAX_STATUS_LENGTH = 500;

export const REPORT_REASON_MESSAGE_MAX_LENGTH = 256;

export const TCNN_SCROLL_START_DELAY_SEC = 3;
export const TCNN_SCROLL_END_DELAY_SEC = 3;
export const TCNN_NON_SCROLLABLE_SHOW_DURATION_SEC = 10;

export const BROADCAST_TITLE_MAX_LENGTH = 256;

export const ONE_CLICK_GIFTING = "oneClickGifting";
export const SELECTED_CURRENCY = "selectedCurrency";

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const YEAR = 365 * DAY;

export const PLUS = "+";

export const LOCK_BY_CAPTCHA_STATUS_CODE = 424;

export const ACCOUNT_TAGS_EXPIRE_MS = 5 * MINUTE;

export const IS_NEW_USER_STORAGE_KEY = "isNewUser";

export const FAIL_AUTOLOGIN_OPEN_CAHSIER_STORAGE_KEY =
  "failAutologinOpenCashier";

export const MARKETPLACE_ID = "marketplace";

export const MIN = "min";
export const MAX = "max";

export const MAX_DISPLAYED_MESSAGES_COUNT = 99;

export const mobileTypes = [
  DeviceType.ANDROID,
  DeviceType.IOS,
  DeviceType.MOBILE_OTHER,
];
export const currencyToSymbol: Record<Currency, string> = {
  [Currency.USD]: "$",
  [Currency.EUR]: "€",
  [Currency.EGP]: "E£",
  [Currency.SAR]: "﷼",
  [Currency.INR]: "₹",
  [Currency.GBP]: "£",
  [Currency.TRY]: "₺",
  [Currency.KWD]: "د.ك",
  [Currency.CAD]: "CA$",
  [Currency.AED]: "د.إ",
  [Currency.QAR]: "ر.ق",
  [Currency.OMR]: "ر.ع.",
  [Currency.RUB]: "₽",
};

export const RETRY_PAY_BUTTON_PORTAL_ID = "retry-pay-button-root";

export const BOTTOM_SCREEN_HIDE_ANIMATION_DURATION = 500;

export const livingStreamStatuses = [
  StreamStatus.INITED,
  StreamStatus.LIVING,
  StreamStatus.SUSPENDED,
];

export const deadStreamStatuses = [
  StreamStatus.TERMINATED,
  StreamStatus.EXPIRED,
];

export const DEFAULT_CAMPAIGN_NAME = "default";

export const UPGRADED_STREAM_ID_STORAGE_KEY = "lwc/upgradedStreamId";

export const DEFAULT_GIFT_LINK = "https://resources.tango.me/web/gift.json";

export const PAYMENT_STATUS_PURCHASE_LIMIT_REACHED = "PURCHASE_LIMIT_REACHED";

export const UTM_PARAMS = [
  UtmParams.UTM_SOURCE,
  UtmParams.UTM_MEDIUM,
  UtmParams.UTM_CAMPAIGN,
  UtmParams.UTM_ID,
  UtmParams.UTM_CONTENT,
  UtmParams.UTM_TERM,
  UtmParams.AD_GROUP_ID,
];

export const UTM_COOKIE_NAME_FIRST_TOUCH_PREFIX = "__ft_";
export const UTM_COOKIE_NAME_PREFIX = "__lt_";

export const GA_COOKIE_NAME = "_ga";

export const languageIsoCodes = [
  "en",
  "ar",
  "nl",
  "fil",
  "fr",
  "de",
  "he",
  "hi",
  "id",
  "it",
  "ja",
  "ko",
  "ms",
  "pt",
  "ro",
  "ru",
  "es",
  "ta",
  "te",
  "th",
  "tr",
  "uk",
  "vi",
] as const;

export const languageByIsoCode: Record<LanguageIsoCode, string> = {
  en: "English",
  ar: "العربية",
  nl: "Nederlands",
  fil: "Filipino",
  fr: "Français",
  de: "Deutsch",
  he: "עִבְרִית",
  hi: "हिन्दी",
  id: "Bahasa Indonesia",
  it: "Italiano",
  ja: "日本語",
  ko: "한국어",
  ms: "Bahasa Melayu",
  pt: "Português",
  ro: "Română",
  ru: "Русский",
  es: "Español",
  ta: "தமிழ்",
  te: "తెలుగు",
  th: "ภาษาไทย",
  tr: "Türkçe",
  uk: "Українська",
  vi: "Tiếng Việt",
};

export const PROTOBUF_NULL_VALUE = "null";

export const PRODUCTION_ENVIRONMENT = "production";

export const homePageMapping = {
  v1: "1",
  v2: "2",
  v3: "3",
};

export const AVAILABLE_HOME_PAGES_VERSIONS = [
  homePageMapping.v1,
  homePageMapping.v2,
  homePageMapping.v3,
];

export const languageIsoCodeMapping = Object.fromEntries(
  languageIsoCodes.map((code) => [code, code])
) as LanguageByIsoCodeMapping;

export const API_ERROR_NETWORK_ERROR_TEXT = "ApiError: network error";

export const AUCTION_TAB_DEFAULT_VALUE: Record<string, string> = {
  [AuctionTab.ALL]: "All",
  [AuctionTab.MY]: "My Bids",
  [AuctionTab.FOLLOW]: "Follow",
};

export const accept = {
  "image/png": [],
  "image/gif": [],
  "image/bmp": [],
  "image/jpeg": [],
};

export const CANVAS_WEBGL_ATTRIBUTES = {
  alpha: false,
  antialias: false,
  depth: false,
  stencil: false,
};

export const WEBGL_RENDERER_EXTENSION_NAME = "WEBGL_debug_renderer_info";
