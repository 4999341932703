import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { MessageDateNow } from "chat/components/MessageDateNow/MessageDateNow";
import { messages } from "chat/components/common/messages";
import {
  MESSAGES_FROM_TANGO_DATE_DEFAULT,
  MESSAGES_WITH_DEFAULT_SPACING,
  MESSAGES_WITHOUT_ABSOLUTE_DATE,
} from "chat/components/currentConversation/constants";
import { TranslateIcon } from "chat/imports/assets";
import { Typography } from "chat/imports/components";
import { TYPOGRAPHY_TYPE } from "chat/imports/constants";
import { ComponentWithClassName } from "chat/imports/types";
import { StoredMessage } from "chat/state/reducer";
import { MessageType } from "chat/types";
import styles from "chat/components/currentConversation/Message.scss";

interface MessageInfoIconsProps {
  message: StoredMessage;
  preventAbsoluteFill: boolean;
  translation?: string;
}

const MessageInfoIcons: ComponentWithClassName<MessageInfoIconsProps> = ({
  message,
  translation,
  className,
  preventAbsoluteFill,
}) => (
  <div
    className={classnames(styles.infoIcons, className, {
      [styles.absolute]:
        !MESSAGES_WITH_DEFAULT_SPACING.includes(message.type) &&
        !MESSAGES_WITHOUT_ABSOLUTE_DATE.includes(message.type) &&
        !preventAbsoluteFill,
      [styles.tangoMessage]: MESSAGES_FROM_TANGO_DATE_DEFAULT.includes(
        message.type
      ),
      [styles.profileMessageDate]: message.type === MessageType.PROFILE_MESSAGE,
    })}
  >
    {message.isTranslated && translation && (
      <div className={styles.translate}>
        <TranslateIcon className={styles.icon} />
      </div>
    )}
    {message.edited && (
      <div className={styles.translate}>
        <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH5} className={styles.edited}>
          <FormattedMessage {...messages.edited} />
        </Typography>
      </div>
    )}
    <Typography type={TYPOGRAPHY_TYPE.PARAGRAPH5} className={styles.time}>
      <MessageDateNow value={Number(message.id.ts)} />
    </Typography>
  </div>
);

export default MessageInfoIcons;
