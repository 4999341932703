import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { chatSelectors } from "chat/exports/state/selectors";
import {
  getIsChatDeleteMessageEnabled,
  getIsChatEditMessageEnabled,
} from "chat/soc/chatSoc";
import { isSystemChat } from "state/abTests";
import { RootState } from "state/delegate";
import { linkToMessageRequest } from "ui/navigation/links";

export const useShowMessageActionsMenu = () => {
  const conversationId = useSelector(
    chatSelectors.getCurrentConversationId
  ) as string;
  const isMessageRequestRoute = useRouteMatch(linkToMessageRequest);
  const isDeleteMessageEnabled = useSelector(getIsChatDeleteMessageEnabled);
  const isEditMessageEnabled = useSelector(getIsChatEditMessageEnabled);
  const isSystem = useSelector((state: RootState) =>
    isSystemChat(state, conversationId)
  );

  const isAnyOptionEnabled = isDeleteMessageEnabled || isEditMessageEnabled;

  return !(!isAnyOptionEnabled || isSystem || isMessageRequestRoute);
};
