import React from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { Breakpoints } from "src/enums";
import Typography from "ui/common/typography/Typography";
import { TYPOGRAPHY_TYPE } from "ui/common/typography/types";
import { useBreakpointPrecise } from "ui/hooks/useBreakpoint";
import { ReactComponent as SoundOffIcon24 } from "img/stream/sound_fill_24.svg";
import { ReactComponent as SoundOffIcon32 } from "img/stream/sound_fill_32.svg";
import styles from "./MutedState.scss";
const MutedState = () => {
  const breakpoint = useBreakpointPrecise();
  const isDesktop = breakpoint === Breakpoints.DESKTOP;
  const SoundIcon = isDesktop ? SoundOffIcon32 : SoundOffIcon24;

  return (
    <div className={classnames(styles.container, styles[breakpoint])}>
      <div className={styles.circle} />
      <SoundIcon className={styles.icon} />
      {isDesktop && (
        <Typography className={styles.title} type={TYPOGRAPHY_TYPE.PARAGRAPH2}>
          <FormattedMessage id="broadcast.lobby.muted" defaultMessage="Muted" />
        </Typography>
      )}
    </div>
  );
};
export default MutedState;
