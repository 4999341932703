import { v4 as uuidv4 } from "uuid";
import {
  EventNames,
  StorageKeys,
} from "src/core/analytics/dataMesh/common/enums";
import { startNewSession } from "src/core/analytics/dataMesh/eventMethods/startNewSession";
import { getSessionStartParams } from "src/core/analytics/dataMesh/utils/dbMethods/getSessionStartParams";
import { sendEventUtil } from "src/core/analytics/dataMesh/utils/sendEventUtil";
import { getAnalyticsPluginEnabled } from "src/core/analytics/dataMesh/utils/store/getStoreEntity";
import { currentTimeMillis } from "src/core/analytics/imports/utils";

interface DataMeshTrackEventParameters {
  screenName: string;
  screenState: string;
}

export const dataMeshTrackEvent = async (
  eventName: EventNames,
  payload: Uint8Array,
  params?: Partial<DataMeshTrackEventParameters>
) => {
  const isAnalyticsPluginGatewayEnabled = getAnalyticsPluginEnabled();

  if (!isAnalyticsPluginGatewayEnabled) {
    return;
  }

  let clientSessionId = (await getSessionStartParams())[
    StorageKeys.SESSION_START_ID
  ];

  if (!clientSessionId) {
    await startNewSession();
  }

  clientSessionId = (await getSessionStartParams())[
    StorageKeys.SESSION_START_ID
  ];

  const timestamp = currentTimeMillis();

  const eventUid = uuidv4();

  sendEventUtil({
    eventName,
    payload,
    eventUid,
    clientSessionId,
    params,
    timestamp,
  });
};
