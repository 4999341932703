import { batch } from "react-redux";
import AnalyticsUtils from "@analytics/AnalyticsUtils";
import { emitMultiStreamInvitationReceived } from "@analytics/multiStreamInvitationEmitters";
import {
  checkImage,
  initStream,
  setStreamStartNotification,
  terminateStream,
} from "api/stream";
import { uploadImage } from "api/upload";
import {
  BroadcastSource,
  BroadcastStatus,
  StreamSessionInitializationResult,
} from "src/enums";
import { broadcastExternalSelectors } from "src/features/broadcastExternal/state/selectors";
import { prepareImageForUpload } from "src/utils/imageUtils";
import {
  getLivePartyInvitesEnabled,
  getLivePartyInvitesOnPublicBroadcastEnabled,
} from "state/abTests";
import {
  endSessionInitialization,
  leftViewerSession,
  resetViewerSession,
} from "state/actionCreators/viewerSession";
import { broadcastSelectors, navigationSelectors } from "state/selectors";
import { broadcastActionCreators } from "state/tree/broadcast";
import logger from "utils/logger";

export const broadcastInit = (props) => async (dispatch, getState) => {
  const {
    broadcastSource,
    broadcastKind,
    ticketPriceInCredits,
    gift,
    landscape,
    defaultBroadcastTitle,
  } = props;
  const state = getState();
  const broadcastPictureUrl = broadcastSelectors.broadcastPictureUrl(state);
  const imageIsOk = await checkImage(broadcastPictureUrl);
  if (!imageIsOk) {
    dispatch(broadcastActionCreators.broadcastBadPicture());

    return;
  }
  AnalyticsUtils.updateInteractionId();
  dispatch(
    broadcastActionCreators.broadcastInitStarted({
      broadcastKind,
      broadcastSource,
    })
  );
  const broadcastTitle = broadcastSelectors.broadcastTitle(state);

  const parsedTitle = broadcastTitle || defaultBroadcastTitle;

  const settings = {
    audioEnabled: true,
    videoEnabled:
      broadcastSelectors.isBroadcastScreenShareEnabled(state) ||
      broadcastSelectors.getBroadcastVideoEnabled(state),
  };

  try {
    await setStreamStartNotification({ message: parsedTitle });
  } catch (error) {
    logger.error("Failed to set stream start notification", error);
  }

  try {
    const result = await initStream({
      title: parsedTitle,
      source: broadcastSource,
      type: broadcastKind,
      ticketPriceInCredits,
      settings,
      gift,
      landscape,
      // We use `broadcastPictureUrl` instead of `broadcastThumbnailUrl` even
      // though it asks for thumbnail for better broadcast picture quality
      thumbnail: broadcastPictureUrl,
    });
    const broadcastId = result.encryptedStreamId;

    if (result.code === "SUCCESS") {
      return batch(() => {
        dispatch(resetViewerSession(broadcastId));
        dispatch(
          endSessionInitialization(
            broadcastId,
            StreamSessionInitializationResult.SUCCESS,
            BroadcastSource.BROADCASTING
          )
        );
        dispatch(
          broadcastActionCreators.broadcastInitSucceeded({
            broadcastId,
            broadcastKey: result.key,
          })
        );
      });
    }
    if (result.code === "EXISTING_LIVE_STREAM") {
      await terminateStream({ streamId: broadcastId });

      return dispatch(broadcastInit(props));
    }
    if (result.userBanDuration) {
      return dispatch(
        broadcastActionCreators.broadcastInitUserBan(result.userBanDuration)
      );
    }

    return dispatch(
      broadcastActionCreators.broadcastInitFailed(
        new Error(`Unknown result code: ${result.code}`)
      )
    );
  } catch (error) {
    return dispatch(broadcastActionCreators.broadcastInitFailed(error));
  }
};

export const broadcastTerminate = () => async (dispatch, getState) => {
  const broadcastId = broadcastSelectors.broadcastId(getState());
  if (!broadcastId) {
    // No broadcast to terminate, act like terminate succeeded for now
    dispatch(broadcastActionCreators.broadcastTerminateSucceeded());

    return;
  }
  AnalyticsUtils.updateInteractionId();
  dispatch(broadcastActionCreators.broadcastTerminateStarted());
  try {
    await terminateStream({ streamId: broadcastId });
    batch(() => {
      dispatch(leftViewerSession(broadcastId));
      dispatch(broadcastActionCreators.broadcastTerminateSucceeded());
    });
  } catch (error) {
    dispatch(broadcastActionCreators.broadcastTerminateFailed(error));
  }
};

export const manageMultiBroadcastInvite = (payload) => (dispatch, getState) => {
  const state = getState();

  const isLivePartyInvitesOnObsBroadcastEnabled =
    getLivePartyInvitesEnabled(state);
  const isLivePartyInvitesOnPublicBroadcastEnabled =
    getLivePartyInvitesOnPublicBroadcastEnabled(state);

  if (
    !isLivePartyInvitesOnObsBroadcastEnabled &&
    !isLivePartyInvitesOnPublicBroadcastEnabled
  ) {
    return;
  }

  const broadcastExternalStatus =
    broadcastExternalSelectors.getBroadcastExternalStatus(state);
  const broadcastId = broadcastSelectors.broadcastId(state);
  const broadcastKind = broadcastSelectors.broadcastKind(state);
  const broadcastStatus = broadcastSelectors.broadcastStatus(state);
  const currentRoute = navigationSelectors.getCurrentRoute(state);

  emitMultiStreamInvitationReceived(payload, {
    broadcastExternalStatus,
    broadcastId,
    broadcastKind,
    broadcastStatus,
    currentRoute,
  });

  dispatch(broadcastActionCreators.broadcastGetInvite(payload));
};

export const broadcastPictureUpload =
  (newPicture) => async (dispatch, getState) => {
    const state = getState();
    const prevBroadcastPictureUrl =
      broadcastSelectors.broadcastPictureUrl(state);
    const prevBroadcastPictureThumbnailUrl =
      broadcastSelectors.broadcastPictureThumbnailUrl(state);
    const pictureUrl = URL.createObjectURL(newPicture);
    dispatch(
      broadcastActionCreators.broadcastPictureUploadStarted({
        broadcastPictureUrl: pictureUrl,
        broadcastPictureThumbnailUrl: pictureUrl,
      })
    );
    try {
      const nextPicturePreparedForUpload =
        await prepareImageForUpload(pictureUrl);
      const {
        url: nextBroadcastPictureUrl,
        thumbnailUrl: nextBroadcastPictureThumbnailUrl,
      } = await uploadImage(nextPicturePreparedForUpload);

      return dispatch(
        broadcastActionCreators.broadcastPictureUploadSucceeded({
          broadcastPictureUrl: nextBroadcastPictureUrl,
          broadcastPictureThumbnailUrl: nextBroadcastPictureThumbnailUrl,
        })
      );
    } catch (error) {
      return dispatch(
        broadcastActionCreators.broadcastPictureUploadFailed(error, {
          prevBroadcastPictureUrl,
          prevBroadcastPictureThumbnailUrl,
        })
      );
    } finally {
      URL.revokeObjectURL(pictureUrl);
    }
  };

export const markPipAsStartedPlayingForBroadcaster =
  (streamId) => async (dispatch, getState) => {
    const state = getState();

    const myBroadcastId = broadcastSelectors.broadcastId(state);
    const myBroadcastStatus = broadcastSelectors.broadcastStatus(state);
    const startedPlayingPipsIds =
      broadcastSelectors.getStartedPlayingPipsIds(state);

    if (
      !myBroadcastId ||
      myBroadcastStatus === BroadcastStatus.TERMINATE_SUCCEEDED ||
      startedPlayingPipsIds.includes(streamId)
    ) {
      return;
    }

    dispatch(broadcastActionCreators.markPipAsStartedPlaying(streamId));
  };
