import { parseBooleanEnabledSOC } from "state/abTests";

export const getIsChatDeleteMessageEnabled = parseBooleanEnabledSOC(
  "web.chats.message.delete.action.enabled",
  "0"
);

export const getIsChatEditMessageEnabled = parseBooleanEnabledSOC(
  "web.chats.message.edit.action.enabled",
  "0"
);
