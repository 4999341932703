export const ADD_LOGIN_PROVIDER = "lwc/login/addProvider";
export const AUTHORIZATION_END = "lwc/login/guestAuthorizationEnd";
export const AUTHORIZATION_START = "lwc/login/guestAuthorizationStart";
export const LOGIN_CANCEL = "lwc/login/loginCancelled";
export const LOGIN_CONTINUE_WITH_PHONE = "lwc/login/continueWithPhone";
export const LOGIN_END = "lwc/login/loginEnd";
export const LOGIN_ENTRY_POINT = "lwc/login/entryPoint";
export const LOGIN_HIDE_IN_PROGRESS_STATE = "lwc/login/hideInProgressState";
export const LOGIN_PROVIDERS_PREPARATION_ENDED =
  "lwc/login/providersPreparation/ended";
export const LOGIN_PROVIDERS_PREPARATION_STARTED =
  "lwc/login/providersPreparation/started";
export const LOGIN_SELECT_COUNTRY = "lwc/login/selectCountry";
export const LOGIN_START = "lwc/login/loginStart";
export const LOGIN_WAITING_FOR_CODE = "lwc/login/waitingForCode";
export const LOGOUT_3RD_PARTY_END = "lwc/login/thirdpartyLogoutEnd";
export const LOGOUT_END = "lwc/login/logoutEnd";
export const LOGOUT_START = "lwc/login/logoutStart";
export const MARK_SESSION_LIMITED = "lwc/login/markSessionLimited";
export const PHONE_LOGIN_ATTEMPT_LIMIT_REACHED =
  "lwc/login/attemptLimitReached";
export const PHONE_LOGIN_METHOD_UNAVAILABLE_FOR_REGISTERED_USER =
  "lwc/login/methodUnavailableForRegisteredUser";
export const PHONE_LOGIN_PHONE_NUMBER_SPECIFIED =
  "lwc/login/phoneNumberSpecified";
export const PHONE_LOGIN_PHONE_VERIFICATION_UNAVAILABLE =
  "lwc/login/phoneVerificationUnavailable";
export const PHONE_LOGIN_RESET = "lwc/login/resetPhoneLogin";
export const PHONE_LOGIN_SET_COUNTRY_INFO =
  "lwc/login/setPhoneCountryInformation";
export const PHONE_LOGIN_SET_PREFERS_SMS = "lwc/login/setPrefersSmsValidation";
export const PHONE_LOGIN_SET_SMS_RESEND_ALLOWED_TIMESTAMP =
  "lwc/login/setSmsResendAllowedTimestamp";
export const PHONE_LOGIN_VERIFICATION_CODE_SPECIFIED =
  "lwc/login/verificationCodeSpecified";
export const PHONE_LOGIN_VERIFICATION_FAILED = "lwc/login/verificationFailed";
export const PHONE_LOGIN_VERIFICATION_REQUIRED =
  "lwc/login/verificationRequired";
export const PHONE_LOGIN_VERIFICATION_REQUIRED_SMS_NOT_POSSIBLE =
  "lwc/login/verificationRequiredNotPossible";
export const SET_REFERRER_ACCOUNT_ID = "lwc/login/referrerAccountId";
export const PHONE_LOGIN_RESEND_CODE = "lwc/login/resendCode";
