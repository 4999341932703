import React, {
  FC,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import MessageActionsMenu from "chat/components/MessageMenu/MessageActionsMenu/MessageActionsMenu";
import ChatAvatar from "chat/components/common/ChatAvatar";
import SystemChatMessage from "chat/components/common/SystemChatMessage";
import IdentityValidationMessage from "chat/components/currentConversation/IdentityValidationMessage";
import ImageMessage from "chat/components/currentConversation/ImageMessage";
import NewDeviceLoginMessage from "chat/components/currentConversation/NewDeviceLoginMessage";
import UnsupportedMessage from "chat/components/currentConversation/UnsupportedMessage";
import VideoMessage from "chat/components/currentConversation/VideoMessage";
import { CallMessage } from "chat/components/currentConversation/components/CallMessage";
import GIFMessage from "chat/components/currentConversation/components/GIFMessage";
import MessageInfoIcons from "chat/components/currentConversation/components/MessageInfoIcons";
import TranslatedMessage from "chat/components/currentConversation/components/TranslatedMessage";
import { systemMessageTypes } from "chat/constants";
import { ChatErrorType } from "chat/enums";
import { DisplayName, Spinner, Typography } from "chat/imports/components";
import {
  Breakpoints,
  TANGO_ACCOUNT_ID,
  TYPOGRAPHY_TYPE,
} from "chat/imports/constants";
import { getIsChatMediaEnabled } from "chat/imports/environment";
import { useBreakpoint } from "chat/imports/hooks";
import {
  RootState,
  deviceInfoSelectors,
  getIsGifMessageEnabled,
  getIsOfflineChatMediaContentEnabled,
  getOfflineChatTranslationEnabled,
  getPremiumMessagesEnabled,
  profilesCacheSelectors,
} from "chat/imports/state";
import { AccountInfo, VoidCallback } from "chat/imports/types";
import { formatDisplayName, makeLinkToProfile } from "chat/imports/utils";
import PremiumMessage from "chat/premiumMessage/ui/components/PremiumMessage";
import { getIsChatDeleteMessageEnabled } from "chat/soc/chatSoc";
import { StoredMessage } from "chat/state/reducer";
import { ChatMessageAnalyticsParams, MessageType } from "chat/types";
import { getExternalMessageStyles } from "chat/utils/getExternalMessageStyles";
import { getGroupMessageClassnames } from "chat/utils/groupMessageClassnames";
import isGroupChatId from "chat/utils/isGroupChatId";
import ExternalMessage from "./ExternalMessage";
import GiftMessage from "./GiftMessage";
import LiveStreamMessage from "./LiveStreamMessage";
import ProfileMessage from "./ProfileMessage";
import Resend from "./Resend";
import SubscriptionMessage from "./SubscriptionMessage";
import { MESSAGES_WITH_DEFAULT_SPACING } from "./constants";
import useMessageTranslate from "./useMessageTranslate";
import styles from "./Message.scss";

export interface CommonProps {
  conversationId: string;
  message: StoredMessage;
}

export interface MessageConfig {
  analyticsParams: ChatMessageAnalyticsParams;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  isMyMessage: boolean;
}

interface MessageContentProps extends CommonProps {
  accountInfo?: AccountInfo;
  isGifMessageEnabled: boolean;
  locale: string;
  messageConfig: MessageConfig;
  onHeightUpdate: VoidCallback;
}

const Content = memo<MessageContentProps>(
  ({
    message,
    conversationId,
    accountInfo,
    locale,
    messageConfig,
    onHeightUpdate,
    isGifMessageEnabled,
  }) => {
    const isPremiumMessagesEnabled = useSelector(getPremiumMessagesEnabled);
    const isMediaContentEnabled = useSelector(
      getIsOfflineChatMediaContentEnabled
    );

    const isChatMediaEnabled = getIsChatMediaEnabled();
    const isMessageFromTango = message.from === TANGO_ACCOUNT_ID;
    const showMediaInChat = isChatMediaEnabled || isMessageFromTango;
    const { isMyMessage } = messageConfig;

    if (message.type === MessageType.TEXT_MESSAGE) {
      return (
        <TranslatedMessage
          locale={locale}
          message={message}
          messageConfig={messageConfig}
        />
      );
    }

    if (message.type === MessageType.SDK_EXTERNAL_MESSAGE) {
      return <ExternalMessage message={message} />;
    }

    if (
      message.type === MessageType.IMAGE_MESSAGE &&
      message.media?.[0] != null
    ) {
      if (!showMediaInChat || (!isMediaContentEnabled && !isMyMessage)) {
        return <UnsupportedMessage messageConfig={messageConfig} />;
      }

      const {
        isUploading,
        width = 0,
        height = 0,
        download_url = "",
      } = message.media[0];

      return isUploading ? (
        <Spinner />
      ) : (
        <ImageMessage
          id={message.id.id}
          conversationId={conversationId}
          height={height}
          isBlurred={message.isBlurred}
          messageConfig={messageConfig}
          url={download_url}
          width={width}
        />
      );
    }

    if (message.type === MessageType.VIDEO_MESSAGE) {
      if (
        !showMediaInChat ||
        message.media?.[0] == null ||
        (!isMediaContentEnabled && !isMyMessage)
      ) {
        return <UnsupportedMessage messageConfig={messageConfig} />;
      }

      return (
        <VideoMessage
          id={message.id.id}
          conversationId={conversationId}
          isBlurred={message.isBlurred}
          media={message.media[0]}
          messageConfig={messageConfig}
          isShowOnlyPreview
        />
      );
    }

    if (message.type === MessageType.GIF_MESSAGE) {
      if (
        (!isMediaContentEnabled && !isMyMessage) ||
        !showMediaInChat ||
        !isGifMessageEnabled ||
        message.media?.[0] == null
      ) {
        return <UnsupportedMessage messageConfig={messageConfig} />;
      }

      const { width = 0, height = 0, download_url = "" } = message.media[0];

      return (
        <GIFMessage
          height={height}
          messageConfig={messageConfig}
          url={download_url}
          width={width}
        />
      );
    }

    if (
      message.type === MessageType.PROFILE_MESSAGE &&
      message.payload != null
    ) {
      return <ProfileMessage message={message} />;
    }

    if (
      message.type === MessageType.LIVE_STREAM &&
      message.media?.[0] != null
    ) {
      return (
        <LiveStreamMessage
          conversationId={conversationId}
          media={message.media[0]}
          messageConfig={messageConfig}
        />
      );
    }

    if (message.type === MessageType.GIFT_IN_CHAT && message.payload) {
      return <GiftMessage message={message} messageConfig={messageConfig} />;
    }

    if (message.type === MessageType.SUBSCRIPTION && message.body != null) {
      return (
        <SubscriptionMessage
          accountInfo={accountInfo}
          conversationId={conversationId}
          message={message}
          messageConfig={messageConfig}
        />
      );
    }

    if (
      message.type === MessageType.KYC_VERIFICATION_REQUESTED &&
      message.body
    ) {
      return <IdentityValidationMessage message={message} />;
    }

    if (
      message.type === MessageType.DEVICE_LOGIN_INFO_MESSAGE &&
      message.body
    ) {
      return <NewDeviceLoginMessage message={message} />;
    }

    if (
      message.type === MessageType.PREMIUM_MESSAGE_SHARED &&
      isPremiumMessagesEnabled
    ) {
      if (!isMediaContentEnabled && !isMyMessage) {
        return <UnsupportedMessage messageConfig={messageConfig} />;
      }

      return message.isMediaLoading ? (
        <Spinner />
      ) : (
        <PremiumMessage
          accountInfo={accountInfo}
          conversationId={conversationId}
          message={message}
          messageConfig={messageConfig}
          onHeightUpdate={onHeightUpdate}
        />
      );
    }

    if (message.type === MessageType.VIDEO_PTT) {
      return <UnsupportedMessage messageConfig={messageConfig} />;
    }

    if (
      message.type === MessageType.MISSED_CALL_MESSAGE ||
      message.type === MessageType.NORMAL_CALL_MESSAGE
    ) {
      if (message.id && message.call_log_payload) {
        return (
          <CallMessage
            messageId={message.id}
            messagePayload={message.call_log_payload}
          />
        );
      }

      return <UnsupportedMessage messageConfig={messageConfig} />;
    }

    return <UnsupportedMessage messageConfig={messageConfig} />;
  }
);

Content.displayName = "Content";

interface MessageProps extends CommonProps {
  accountInfo: AccountInfo | undefined;
  isMessageMenuEnabled: boolean;
  messageConfig: MessageConfig;
}

const Message: FC<MessageProps> = ({
  message,
  conversationId,
  accountInfo,
  messageConfig,
  isMessageMenuEnabled,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const intl = useIntl();
  const { first_name, last_name, thumbnail_url, account_id } =
    accountInfo || {};
  const basicProfile = useSelector(
    useCallback(
      (state: RootState) =>
        profilesCacheSelectors.getBasicProfile(state, message.from),
      [message.from]
    ),
    shallowEqual
  );
  const locale = useSelector(deviceInfoSelectors.getDeviceLocale);
  const isOfflineChatTranslationEnabled = useSelector(
    getOfflineChatTranslationEnabled
  );
  const isDeleteMessageEnabled = useSelector(getIsChatDeleteMessageEnabled);
  const isTranslationEnabled =
    isOfflineChatTranslationEnabled && !isDeleteMessageEnabled;
  const isGifMessageEnabled = useSelector(getIsGifMessageEnabled);
  const isMediaContentEnabled = useSelector(
    getIsOfflineChatMediaContentEnabled
  );

  const isGiftMessage = message.type === MessageType.GIFT_IN_CHAT;
  const isCallMessage =
    (message.type === MessageType.MISSED_CALL_MESSAGE ||
      message.type === MessageType.NORMAL_CALL_MESSAGE) &&
    message.id &&
    message.call_log_payload;
  const isGroupChat = isGroupChatId(conversationId);
  const { isMyMessage, isFirstInGroup, isLastInGroup } = messageConfig;
  const translation = message.translation?.[locale];

  const breakpoint = useBreakpoint();

  const translateMessage = useMessageTranslate({
    message,
    locale,
    conversationId,
    isMyMessage,
  });

  useEffect(() => {
    if (translation || message.error) {
      contentRef.current?.style.removeProperty("min-height");
    }
  }, [translation, message.error]);

  const translate = useCallback(() => {
    if (!translation && contentRef.current) {
      contentRef.current.style.setProperty(
        "min-height",
        `${contentRef.current.getBoundingClientRect().height}px`
      );
    }

    translateMessage();
  }, [translation, translateMessage]);

  const recalculateMinHeight = (element: HTMLDivElement) => {
    element.style.removeProperty("min-height");

    setTimeout(() => {
      element.style.setProperty(
        "min-height",
        `${element.getBoundingClientRect().height}px`
      );
    }, 0);
  };

  const onHeightUpdate = useCallback(() => {
    if (contentRef.current) {
      recalculateMinHeight(contentRef.current);
    }
  }, []);

  const handleNameClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.stopPropagation();
    },
    []
  );

  if (systemMessageTypes.includes(message.type)) {
    return (
      <Typography
        type={
          breakpoint === Breakpoints.DESKTOP
            ? TYPOGRAPHY_TYPE.PARAGRAPH2
            : TYPOGRAPHY_TYPE.PARAGRAPH4
        }
        as="div"
        className={styles.systemMessage}
        data-testid="system-message"
      >
        <SystemChatMessage basicProfile={basicProfile} message={message} />
      </Typography>
    );
  }

  const showInGroupChatOnly = isGroupChat && !isMyMessage;
  const isMessageFromChatOwner = account_id === message.from;
  const displayNameProps = isMessageFromChatOwner
    ? {
        firstName: first_name,
        lastName: last_name,
      }
    : {};

  const isNameDisplayed =
    showInGroupChatOnly &&
    isFirstInGroup &&
    ![MessageType.LIVE_STREAM, MessageType.PREMIUM_MESSAGE_SHARED].includes(
      message.type
    );

  const conditionalContainerStyle = {
    [styles.pending]: message.isPending,
    [styles.imageMessage]:
      message.type === MessageType.IMAGE_MESSAGE ||
      (isGifMessageEnabled && message.type === MessageType.GIF_MESSAGE),
    [styles.liveStreamMessage]: message.type === MessageType.LIVE_STREAM,
  };

  const conditionalMessageStyle = {
    [styles.clickable]: !isMyMessage && isTranslationEnabled && !isCallMessage,
    [styles.defaultSpacing]:
      MESSAGES_WITH_DEFAULT_SPACING.includes(message.type) ||
      ((!isGifMessageEnabled || (!isMediaContentEnabled && !isMyMessage)) &&
        message.type === MessageType.GIF_MESSAGE) ||
      (!isMediaContentEnabled &&
        !isMyMessage &&
        message.type === MessageType.PREMIUM_MESSAGE_SHARED) ||
      (!isMediaContentEnabled &&
        !isMyMessage &&
        message.type === MessageType.VIDEO_MESSAGE) ||
      (!isMediaContentEnabled &&
        !isMyMessage &&
        message.type === MessageType.AUDIO_MESSAGE) ||
      (!isMediaContentEnabled &&
        !isMyMessage &&
        message.type === MessageType.IMAGE_MESSAGE),
    [styles.callMessage]: isCallMessage,
  };

  return (
    <div
      className={classnames(styles.root, styles[breakpoint], {
        [styles.myMessage]: isMyMessage,
        [styles.isolate]: !isPopoverOpen,
        [styles.noSelection]: isMessageMenuEnabled,
        [styles.giftMessage]: message.type === MessageType.GIFT_IN_CHAT,
      })}
    >
      {showInGroupChatOnly && (
        <div className={styles.avatarContainer}>
          {isLastInGroup && (
            <ChatAvatar
              name={formatDisplayName({
                intl,
                basicProfile,
                ...displayNameProps,
              })}
              pictureUrl={
                basicProfile?.profileThumbnailUrl ||
                (isMessageFromChatOwner && thumbnail_url)
              }
              to={
                message.from !== TANGO_ACCOUNT_ID
                  ? makeLinkToProfile(message.from, basicProfile)
                  : undefined
              }
              accountInfo={accountInfo}
              basicProfile={basicProfile}
              className={styles.avatar}
              classNamePicture={styles.picture}
              conversationId={conversationId}
              isGroupAvatar
              isSmall
              messageSenderId={message.from}
            />
          )}
        </div>
      )}
      <MessageActionsMenu
        isMessageMenuEnabled={isMessageMenuEnabled}
        message={message}
        isMyMessage={isMyMessage}
        isPopoverOpen={isPopoverOpen}
        setPopoverOpen={setPopoverOpen}
      >
        <div
          className={classnames(
            styles.container,
            conditionalContainerStyle,
            getExternalMessageStyles(styles, message),
            getGroupMessageClassnames(styles, messageConfig)
          )}
          data-testid={`message-${message.from}-${message.id.id || 0}`}
        >
          <div
            className={classnames(styles.message, conditionalMessageStyle)}
            onClick={isTranslationEnabled ? translate : undefined}
          >
            {isNameDisplayed && (
              <Link
                className={styles.name}
                data-testid="message-sender-username"
                onClick={handleNameClick}
                to={makeLinkToProfile(message.from, basicProfile)}
              >
                <DisplayName
                  basicProfile={basicProfile}
                  {...displayNameProps}
                />
              </Link>
            )}
            <Typography
              ref={contentRef}
              type={TYPOGRAPHY_TYPE.PARAGRAPH2}
              className={styles.content}
              data-testid="message-content"
            >
              <Content
                accountInfo={accountInfo}
                conversationId={conversationId}
                isGifMessageEnabled={isGifMessageEnabled}
                locale={locale}
                message={message}
                messageConfig={messageConfig}
                onHeightUpdate={onHeightUpdate}
              />
              {!isCallMessage && (
                <MessageInfoIcons
                  className={classnames({
                    [styles.infoIconsSpaceReserve]: !isGiftMessage,
                  })}
                  message={message}
                  translation={translation}
                  preventAbsoluteFill={!isMediaContentEnabled && !isMyMessage}
                />
              )}
            </Typography>
            {!isGiftMessage && !isCallMessage && (
              <MessageInfoIcons
                className={styles.infoIconsVisible}
                message={message}
                translation={translation}
                preventAbsoluteFill={!isMediaContentEnabled && !isMyMessage}
              />
            )}
          </div>
          {message.error === ChatErrorType.SEND_MESSAGE_ERROR && (
            <Resend
              message={message}
              className={styles.resend}
              analyticsParams={messageConfig.analyticsParams}
            />
          )}
        </div>
      </MessageActionsMenu>
    </div>
  );
};

export default memo(Message);
