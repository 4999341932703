import React, { FC, PropsWithChildren, useEffect, useRef } from "react";
import emptyFunction from "fbjs/lib/emptyFunction";
import { VoidCallback } from "src/features/giftAnimation/imports/types";

interface VideoProps {
  isSoundEnabled: boolean;
  onComplete?: VoidCallback;
  onFrame: (currentTime: number) => void;
  videoUrl?: string;
}

const MAX_FPS = 24;
const MS_PER_SECOND = 1000;
const MEDIA_READY_TO_PLAY = 2;

export const Video: FC<PropsWithChildren<VideoProps>> = ({
  onFrame,
  onComplete,
  isSoundEnabled,
  videoUrl,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const lastTimeRef = useRef<number>(0);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) {
      return;
    }

    let rafId: number;
    let isPlaying = false;

    const updateFrame = () => {
      if (!isPlaying) {
        return;
      }

      const currentTime = performance.now();
      const elapsed = currentTime - lastTimeRef.current;
      const fps = MS_PER_SECOND / elapsed;
      const limitedFPS = Math.min(MAX_FPS, fps);
      const frameInterval = MS_PER_SECOND / limitedFPS;

      if (elapsed >= frameInterval) {
        onFrame(video.currentTime);
        lastTimeRef.current = currentTime;
      }

      rafId = requestAnimationFrame(updateFrame);
    };

    const startPlayback = () => {
      if (!video || isPlaying) {
        return;
      }

      if (video.readyState >= MEDIA_READY_TO_PLAY) {
        const playingVideo = video.play();

        if (playingVideo !== undefined) {
          playingVideo
            .then(() => {
              isPlaying = true;
              if (isSoundEnabled) {
                video.muted = false;
              }
              lastTimeRef.current = performance.now();
              rafId = requestAnimationFrame(updateFrame);
            })
            .catch(emptyFunction);
        }
      }
    };

    video.addEventListener("canplaythrough", startPlayback);

    return () => {
      isPlaying = false;
      cancelAnimationFrame(rafId);
      video.removeEventListener("canplaythrough", startPlayback);
      video.pause();
    };
  }, [isSoundEnabled, onFrame]);

  return (
    <video
      ref={videoRef}
      crossOrigin="anonymous"
      src={videoUrl}
      autoPlay
      muted
      playsInline
      onEnded={onComplete}
      preload="auto"
      disablePictureInPicture
    />
  );
};
