import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchMosV2Lineup } from "src/features/mos/api/mos";
import {
  FetchMosV2LineupParams,
  FetchMosV2LineupResponse,
  MosError,
  MosV2LineupRequestRejectError,
} from "src/features/mos/types";

export const getMosV2Action = createAsyncThunk<
  FetchMosV2LineupResponse,
  FetchMosV2LineupParams,
  MosV2LineupRequestRejectError
>("mosV2/get", async ({ streamId, streamerId }, { rejectWithValue }) => {
  try {
    const { items, lineupId } = await fetchMosV2Lineup({
      streamId,
      streamerId,
    });

    return { items, lineupId };
  } catch (error) {
    if (error instanceof Error) {
      const message = error.message || MosError.SOMETHING_WENT_WRONG;

      return rejectWithValue({ message });
    }

    // Fallback if error does not contain expected structure
    return rejectWithValue({ message: MosError.SOMETHING_WENT_WRONG });
  }
});
